<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #F8F8F8">
            Effektübersicht
        </div>
        <div class="card-body px-0" style="background-color: #F8F8F8">
            <b-card title="Projekte" class="mb-3 shadow">
                <div class="table-scrollable">
                    <b-table striped hover :items="projects" :fields="fields">
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.id)"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" v-show="data.index < projects.length-1" @click="moveItemDown(data.item.id)"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="'/app/project/view/' + data.item.id" ><b-icon icon="eye" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="'/app/project/edit/' + data.item.id" ><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                        </template>
                        <template #cell(Details)="row">
                            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails"></b-form-checkbox>
                        </template>

                        <template #row-details="row">
                            <p>{{ row.item.notes }}</p>
                        </template>
                    </b-table> 
                </div>
            </b-card>
            <br />
            <b-card title="Effekte" class="mb-3 shadow" v-if="hasRole('Administrator')||hasRole('Berater')" >
                <grafik-component></grafik-component>
            </b-card>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import numberHelper from '../../../../helpers/numberHelper';
import { dateHelper } from '../../../mixins/dateHelper';
import ProjectService from '../../../../services/ProjectService';

export default {
    name: 'controlling',
    mixins: [dateHelper],
    data() {
        return {
            projectContext: null,
        }
    },
    created() {
        this.fetchProjects();
    },
    watch: {
        '$route': 'fetchProjects'
    },
    methods: {
        ...mapActions({
            fetchProjects: 'projects/fetchProjects',
        }),
        moveItemUp(projectId) {
            this.$store.commit('loading/setLoading', true);
            ProjectService.moveProjectUp(projectId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchProjects();
                });
        },
        moveItemDown(projectId) {
            this.$store.commit('loading/setLoading', true);
            ProjectService.moveProjectDown(projectId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchProjects();
                });
        }
    },
    computed: {
        ...mapGetters({
            projects: 'projects/projects',
            hasRole: 'auth/hasRole',
        }),
        fields() {
            return [
                { key: 'category_level1',                   label: 'Obergruppe'                            , sortable: true },
                { key: 'project_title',                     label: 'Projektname'                           , sortable: true },          
                { key: 'purchasing_volume',                 label: 'Basisvolumen'                          , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'potential_abs_recurring',           label: 'Potenzial abs. wiederkehrend'          , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'potential_perc_recurring',          label: 'Potenzial % wiederkehrend'             , sortable: true },
                { key: 'savings_abs_recurring',             label: 'Einsparung abs. wiederkehrend'         , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'savings_perc_recurring',            label: 'Einsparung % wiederkehrend'            , sortable: true },
                { key: 'satus_implementation',              label: 'Umsetzungsstatus'                      , sortable: true },
                { key: 'notes',                             label: 'Bemerkungen'                           , sortable: true },
                { key: 'actions',                           label: 'Aktionen'                                               }
            ];
        },
    }
};
</script>
