<template>
<div v-if="$i18n.locale=='de'">
    <div class="terms-of-use">
        <ol>
            <li>
                <strong>Gegenstand, Rechtsstellung von RSM Ebner Stolz</strong>
                <ol>
                    <li>Die RSM Ebner Stolz-Beschaffungsplattform ist eine digitale Plattform für Ausschreibungen und Auktionen, auf der die RSM Ebner Stolz Management Consultants GmbH („RSM Ebner Stolz“) im Auftrag von Kunden angebotsrelevante Informationen über den Bezug von Waren und Dienstleistungen anfragt und potenzielle Lieferanten als registrierte Benutzer entsprechende angebotsrelevante Informationen über ihr Waren- und Dienstleistungsangebot einstellen können.</li>
                    <li>Durch die Ausschreibung oder Auktion von Waren oder Dienstleistungen auf der RSM Ebner Stolz-Beschaffungsplattform sowie durch die Abgabe von Geboten über Waren oder Dienstleistungen wird kein Vertrag zwischen den Benutzern geschlossen. Die Einstellung von Inhalten erfolgt vielmehr ausschließlich zum Zwecke der Vorbereitung separater Vertragsabschlüsse zwischen den Kunden von RSM Ebner Stolz und den Anbietern von Waren und Dienstleistungen. RSM Ebner Stolz ist nicht berechtigt, Kunden für den Bezug der auf dieser Plattform ausgeschriebenen Waren und Dienstleistungen zu vertreten und bietet mit der Ausschreibung auch nicht an, die ausgeschriebenen Waren und Dienstleistungen selbst zu erwerben. <strong>Alle auf dieser Beschaffungsplattform durch die Bieter angebotenen Preise stellen ein rechtlich gültiges und verbindliches Angebot dar.</strong></li>
                </ol>
            </li>

            <li>
                <strong>Registrierung</strong>
                <ol>
                    <li>Die Nutzung dieser Beschaffungsplattform setzt die Anmeldung und eine Registrierung als Benutzer durch RSM Ebner Stolz voraus. Ein Anspruch auf Registrierung besteht nicht. Die Anmeldung und Registrierung sind für den Benutzer kostenlos. Die von RSM Ebner Stolz bei der Anmeldung und Registrierung abgefragten Daten sind von dem Benutzer vollständig und zutreffend anzugeben. Nachträgliche Änderungen der angegebenen Daten können durch den Benutzer eigenständig durchgeführt werden.</li>
                    <li>Von RSM Ebner Stolz mitgeteilte Benutzernamen und Passwörter sind für eine Erstregistrierung vorgesehen und von dem Benutzer jeweils geheim zu halten und bei der Erstregistrierung abzuändern. RSM Ebner Stolz verpflichtet sich, das an den jeweiligen registrierten Benutzer vergebene Passwort nicht an Dritte weiterzugeben.</li>
                </ol>
            </li>

            <li>
                <strong>Nutzungsumfang</strong>
                <ol>
                    <li>RSM Ebner Stolz stellt die RSM Ebner Stolz-Beschaffungsplattform im Rahmen der technischen und betrieblichen Möglichkeiten von RSM Ebner Stolz zur Verfügung.</li>
                    <li>Aufgrund nicht näher vorhersehbarer und steuerbarer gleichzeitiger Zugriffe auf den Server durch registrierte Benutzer und / oder des Zugriffs anderer Internet-Nutzer und aufgrund höherer Gewalt (einschließlich Streiks und / oder Aussperrungen) sowie wegen ggf. erforderlichen Wartungsarbeiten und Reparaturen der Anlagen von RSM Ebner Stolz, die für einen ordnungsgemäßen und verbesserten Betrieb des IT-Systems von RSM Ebner Stolz erforderlich sind, kann es zeitweilig zu Verzögerungen, Störungen und / oder Unterbrechungen des Zugriffs kommen. Wartungs- und Reparaturarbeiten werden von RSM Ebner Stolz nach Möglichkeit außerhalb der üblichen Geschäftszeiten durchgeführt und dem Benutzer im Voraus per E-Mail angekündigt.</li>
                </ol>
            </li>

            <li>
                <strong>Nutzungsregeln</strong>
                <ol>
                    <li>
                        Der Benutzer verpflichtet sich, bei der Nutzung der RSM Ebner Stolz-Beschaffungsplattform:
                        <ul>
                            <li>Gebote nur dann abzugeben, wenn er in der Lage ist, die entsprechenden Leistungen zu erfüllen und ausdrücklich dazu bereit ist, über die Lieferung der betreffenden Waren bzw. die Erbringung der betreffenden Dienstleistungen Vertragsverhandlungen aufzunehmen und abzuschließen</li>
                            <li>Die von der RSM Ebner Stolz vorgegebenen programmtechnischen Anleitungen zur Nutzung der RSM Ebner Stolz-Beschaffungsplattform  einzuhalten und jegliche Manipulationen der Daten und / oder des Programmablaufs zu unterlassen</li>
                            <li>Erforderliche Vorkehrungen zur regelmäßigen Sicherung der ihm über die RSM Ebner Stolz-Beschaffungsplattform übermittelten Daten einzurichten und aufrechtzuerhalten</li>
                            <li>RSM Ebner Stolz unverzüglich erkennbare Mängel, Schäden und / oder Störungen anzuzeigen</li>
                        </ul>
                    </li>
                    <li>Der Benutzer verpflichtet sich, die RSM Ebner Stolz-Beschaffungsplattform nach diesem Vertrag ausschließlich für eigene Zwecke zu nutzen; eine Überlassung der Nutzung an Dritte ist nicht gestattet.</li>
                    <li>Es besteht kein Anspruch auf Aufnahme von Vertragsverhandlungen oder auf Abschluss eines Vertrages über die ausgeschriebenen und / oder angebotenen Leistungen.</li>
                </ol>
            </li>

            <li>
                <strong>Einhaltung gesetzlicher Vorschriften, Schutzrechte Dritter, Freistellung, Sperre</strong>
                <ol>
                    <li>Der Benutzer verpflichtet sich, bei den auf der RSM Ebner Stolz-Beschaffungsplattform enthaltenen Daten und bei der Eingabe und dem Abruf von Daten über die RSM Ebner Stolz-Beschaffungsplattform gesetzliche und behördliche Vorschriften, insbesondere des Datenschutzrechts, des Strafrechts, des Urheber- und / oder Markenrechts und / oder anderer Vorschriften des gewerblichen Rechtsschutzes einzuhalten und keine Rechte Dritter zu verletzen.</li>
                    <li>RSM Ebner Stolz wird Benutzer im Rahmen des gesetzlich Zulässigen unverzüglich informieren, wenn Dritte oder Behörden ihr gegenüber geltend machen, dass ein den Benutzern gem. Ziffer 5.1 zuzurechnender Verstoß gegen gesetzliche und / oder behördliche Vorschriften bzw. eine Verletzung von Rechten Dritter vorliegt.</li>
                    <li>Der Benutzer wird RSM Ebner Stolz von jeglicher Haftung aufgrund eines Verstoßes im Sinne der Ziffer 5.1 freistellen und RSM Ebner Stolz nach besten Kräften bei der Rechtsverteidigung unterstützen. Beruht die RSM Ebner Stolz zur Last gelegte Rechtsverletzung darauf, dass vom Benutzer oder auf Veranlassung des Benutzers von RSM Ebner Stolz online zugänglich gemachte Daten, Gestaltungen und / oder sonstige Informationen Urheberrechte, Markenrechte und / oder andere gewerbliche Schutzrechte Dritter verletzen, so kann RSM Ebner Stolz vom Benutzer verlangen, dass dieser etwaige Schadensersatzbeträge und Kosten der angemessenen Rechtsverteidigung übernimmt, soweit RSM Ebner Stolz kein Mitverschulden zur Last fällt.</li>
                    <li>Besteht ein hinreichender Verdacht, dass ein Verstoß im Sinne der Ziffer 5.1 vorliegt, ist RSM Ebner Stolz berechtigt, die RSM Ebner Stolz-Beschaffungsplattform für den Benutzer bis zum Nachweis der Rechtmäßigkeit zu sperren.</li>
                </ol>
            </li>

            <li>
                <strong>Verantwortlichkeit für Inhalte, Datenschutz</strong>
                <ol>
                    <li>RSM Ebner Stolz übernimmt keine Gewähr und / oder Garantie für die Beschreibung, Eigenschaften, Preise und den Bedarf von Kunden für die auf der RSM Ebner Stolz-Beschaffungsplattform ausgeschriebenen Waren und Dienstleistungen sowie für das Zustandekommen eines Vertrags zwischen Kunden und Benutzern der RSM Ebner Stolz-Beschaffungsplattform, die ein Gebot abgegeben haben.</li>
                    <li>RSM Ebner Stolz hat keinerlei Kontrolle über die Beschaffenheit, Sicherheit und / oder Rechtmäßigkeit der Waren und Dienstleistungen, die von Benutzern als Gebot auf der RSM Ebner Stolz-Beschaffungsplattform eingestellt werden, und prüft diese im Rahmen dieser Nutzungsbedingungen auch nicht nach. Gleiches gilt für die inhaltliche Korrektheit der entsprechenden Gebote und die Fähigkeit der Benutzer, die betreffenden Waren zu liefern bzw. Leistungen zu erbringen.</li>
                    <li>Im Zusammenhang mit dem Betrieb der RSM Ebner Stolz-Beschaffungsplattform erhebt, verarbeitet und nutzt RSM Ebner Stolz die personenbezogenen Daten sowie die Nutzungsdaten des Benutzers in automatisierten Verfahren.</li>
                </ol>
            </li>

            <li>
                <strong>Haftungsbeschränkung</strong>
                <ol>
                    <li>Soweit in den nachstehenden Ziffern 7.2 und 7.3 nichts Abweichendes geregelt ist, ist eine Haftung von RSM Ebner Stolz auf Schadensersatz - ohne Rücksicht auf die Rechtsnatur des geltend gemachten Anspruchs und auch nach Ablauf einer ihr etwa gesetzten Frist zur Leistung - ausgeschlossen. RSM Ebner Stolz haftet vorbehaltlich der nachstehenden Ziffern 7.2 und 7.3 insbesondere nicht für die über die RSM Ebner Stolz-Beschaffungsplattform abrufbaren und eingegebenen Informationen - weder für deren Richtigkeit, Vollständigkeit oder Aktualität, noch für deren Rechtmäßigkeit oder Unbelastetheit von Rechten Dritter - noch für Schäden an Rechtsgütern des Benutzers sowie für entgangenen Gewinn, ausgebliebene Einsparungen, Schäden aus Ansprüchen Dritter und sonstige mittelbare Folgeschäden.</li>
                    <li>Für alle Vermögensschäden durch die Erbringung von Telekommunikationsdienstleistungen haftet RSM Ebner Stolz gem. § 7 Abs. 2 TKV bis zu einem Betrag von 12.500 EUR je Nutzer. Gegenüber der Gesamtheit der Geschädigten ist die Haftung der RSM Ebner Stolz auf 10 Millionen EUR je schadensverursachendes Ereignis begrenzt. Übersteigen die Entschädigungen, die mehreren aufgrund desselben Ereignisses zu leisten sind, die Höchstgrenze, so wird der Schadensersatz in dem Verhältnis gekürzt, in dem die Summe aller Schadensersatzansprüche zur Höchstgrenze steht. Die Haftungsbegrenzung der Höhe nach entfällt, wenn der Schaden vorsätzlich verursacht wurde.</li>
                    <li>Mit Ausnahme von Vermögensschäden durch die Erbringung von Telekommunikationsdienstleistungen gem. § 7 Abs. 2 TKV gelten die Haftungsbeschränkungen gem. Ziffer 7.1 nicht für Körperschäden, Schäden an Gesundheit und Leben sowie Personen- und Sachschäden nach dem Produkthaftungsgesetz und für Schäden, die die RSM Ebner Stolz vorsätzlich oder grob fahrlässig zu vertreten hat, und nicht für das Fehlen zugesicherter Eigenschaften. Sie gelten ferner nicht im Falle arglistigen Verschweigens eines Mangels oder der Übernahme einer Beschaffenheitsgarantie und / oder für Schäden, die infolge leicht fahrlässiger Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) entstanden sind. Insoweit haftet RSM Ebner Stolz nach Maßgabe der gesetzlichen Vorschriften unbeschränkt.</li>
                </ol>
            </li>

            <li>
                <strong>Vertragslaufzeit, Kündigung</strong>
                <ol>
                    <li>Dieser Vertrag kann von jeder Vertragspartei mit einer Kündigungsfrist von 3 Tagen schriftlich gekündigt werden.</li>
                    <li>Das Recht zur Kündigung aus wichtigem Grund bleibt unberührt.</li>
                </ol>
            </li>

            <li>
                <strong>Schlussbestimmungen</strong>
                <ol>
                    <li>Dieser Vertrag unterliegt deutschem, materiellem Recht. Die Anwendung des UN-Übereinkommens über den internationalen Warenkauf ist ausgeschlossen.</li>
                    <li>Ist der Benutzer Kaufmann oder hat er in Deutschland keinen allgemeinen Gerichtsstand, ist Köln ausschließlicher Gerichtsstand für alle Streitigkeiten zwischen dem Benutzer und RSM Ebner Stolz.</li>
                </ol>
            </li>
        </ol>
    </div>
</div>

<div v-else-if="$i18n.locale=='en'">
    <div class="terms-of-use">
        <ol>
            <li>
                <strong> Subject matter, legal position of RSM Ebner Stolz </strong>
                <ol>
                    <li> The RSM Ebner Stolz Procurement Platform is a digital platform for tenders and auctions on which RSM Ebner Stolz Management Consultants GmbH ("RSM Ebner Stolz") requests bid-relevant information on behalf of customers regarding the procurement of goods and services and potential suppliers can post corresponding bid-relevant information about their range of goods and services as registered users.</li>
                    <li> No contract is concluded between the users through the tendering or auctioning of goods or services on the RSM Ebner Stolz procurement platform or through the submission of bids for goods or services. Rather, the posting of content is solely for the purpose of preparing separate contract conclusions between RSM Ebner Stolz's customers and the providers of goods and services. RSM Ebner Stolz is not authorized to represent clients for the purchase of the goods and services advertised on this platform and does not offer to purchase the advertised goods and services itself with the bidding.<strong> All prices offered on this procurement platform by the bidders constitute a legally valid and binding offer.</strong></li>
                </ol>
            </li>

            <li>
                <strong> Registration</strong>
                <ol>
                    <li> The use of this procurement platform requires login and registration as a user by RSM Ebner Stolz. There is no entitlement to registration. Login and registration are free of charge for the user. The data requested by RSM Ebner Stolz during login and registration must be provided by the user completely and accurately. Subsequent changes to the specified data can be made by the user independently.</li>
                    <li> User names and passwords provided by RSM Ebner Stolz are intended for initial registration and must be kept secret by the user and changed upon initial registration. RSM Ebner Stolz undertakes not to disclose the password issued to the respective registered user to third parties.</li>
                </ol>
            </li>

            <li>
                <strong> Extent of Use</strong>
                <ol>
                    <li> RSM Ebner Stolz provides the RSM Ebner Stolz procurement platform within the scope of RSM Ebner Stolz's technical and operational capabilities.</li>
                    <li> Due to not more precisely foreseeable and controllable simultaneous access to the server by registered users and / or access by other Internet users and due to force majeure (including strikes and / or lockouts) as well as due to possibly necessary maintenance work and repairs of RSM Ebner Stolz's facilities, which are required for a proper and improved operation of RSM Ebner Stolz's IT system, delays, malfunctions and / or interruptions of access may occur temporarily. Maintenance and repair work will be carried out by RSM Ebner Stolz outside normal business hours if possible and the user will be notified in advance by e-mail.</li>
                </ol>
            </li>

            <li>
                <strong>Terms of Use</strong>
                <ol>
                    <li>
                        The user agrees, when using the RSM Ebner Stolz procurement platform:
                        <ul>
                            <li>To submit bids only if he is able to perform the relevant services and is expressly prepared to enter into and conclude contractual negotiations for the supply of the relevant goods or the provision of the relevant services</li>
                            <li>To comply with the program-technical instructions provided by RSM Ebner Stolz for the use of the RSM Ebner Stolz procurement platform and to refrain from any manipulation of the data and / or the program sequence</li>
                            <li> To establish and maintain necessary precautions for the regular safeguarding of the data transmitted to it via the RSM Ebner Stolz procurement platform </li>
                            <li> Immediately notify RSM Ebner Stolz of any recognizable defects, damages and / or malfunctions</li>
                        </ul>
                    </li>
                    <li> The user agrees to use the RSM Ebner Stolz procurement platform in accordance with this agreement exclusively for his own purposes; transfer of use to third parties is not permitted.</li>
                    <li> There is no right to enter into contract negotiations or to conclude a contract for the advertised and / or offered services.</li>
                </ol>
            </li>

            <li>
                <strong> Compliance with legal requirements, third-party property rights, indemnification, blocking</strong>
                <ol>
                    <li> The user agrees to comply with legal and official regulations, in particular data protection law, criminal law, copyright and / or trademark law and / or other regulations of industrial property protection, and not to violate any rights of third parties with regard to the data contained on the RSM Ebner Stolz procurement platform and the entry and retrieval of data via the RSM Ebner Stolz procurement platform.    </li>
                    <li> RSM Ebner Stolz will inform users without delay within the scope of what is legally permissible if third parties or authorities assert against it that a violation of statutory and / or official regulations or a violation of third party rights attributable to the users pursuant to section 5.1 exists.</li>
                    <li> The user shall indemnify RSM Ebner Stolz against any liability arising from an infringement within the meaning of section 5.1 and shall support RSM Ebner Stolz in its legal defense to the best of its ability. If the infringement of rights attributed to RSM Ebner Stolz is based on the fact that data, designs and / or other information made accessible online by the user or at the instigation of the user of RSM Ebner Stolz infringe copyrights, trademark rights and / or other industrial property rights of third parties, RSM Ebner Stolz may require the user to assume any amounts of damages and costs of reasonable legal defense, provided RSM Ebner Stolz is not guilty of contributory negligence.li>
                    <li> If there is sufficient suspicion that a violation within the meaning of section 5.1 has occurred, RSM Ebner Stolz shall be entitled to block the RSM Ebner Stolz procurement platform for the User until proof of legality has been provided.</li>
                </ol>
            </li>

            <li>
                <strong> Responsibility for Content, Data Protection</strong>
                <ol>
                    <li> RSM Ebner Stolz does not assume any warranty and / or guarantee for the description, properties, prices and the needs of customers for the goods and services advertised on the RSM Ebner Stolz procurement platform and for the conclusion of a contract between customers and users of the RSM Ebner Stolz procurement platform who have submitted a bid.</li>
                    <li> RSM Ebner Stolz has no control over the quality, safety and / or legality of the goods and services posted by users as bids on the RSM Ebner Stolz procurement platform, nor does it verify them within the scope of these Terms of Use. The same applies to the correctness of the content of the corresponding bids and the ability of the users to deliver the relevant goods or provide services.</li>
                    <li> In connection with the operation of the RSM Ebner Stolz procurement platform, RSM Ebner Stolz collects, processes and uses the personal data as well as the usage data of the user in automated procedures.</li>
                </ol>
            </li>

            <li>
                <strong> Limitation of Liability</strong>
                <ol>
                    <li> Unless otherwise provided for in clauses 7.2 and 7.3 below, RSM Ebner Stolz shall not be liable for damages - irrespective of the legal nature of the asserted claim and even after expiry of any deadline set for performance. Subject to clauses 7.2 and 7.3 below, RSM Ebner Stolz shall in particular not be liable for the information retrievable and entered via the RSM Ebner Stolz procurement platform - neither for its correctness, completeness or up-to-dateness, nor for its legality or unencumberedness by third party rights - nor for damages to the user's legal assets as well as for lost profits, lost savings, damages from third party claims and other indirect consequential damages.</li>
                    <li> RSM Ebner Stolz shall be liable for all financial losses caused by the provision of telecommunication services pursuant to Section 7 (2) TKV up to an amount of EUR 12,500 per user. RSM Ebner Stolz's liability towards the injured parties as a whole shall be limited to EUR 10 million per event causing damage. If the compensation to be paid to several parties due to the same event exceeds the maximum limit, the compensation shall be reduced in the ratio in which the sum of all compensation claims stands to the maximum limit. The limitation of liability in terms of amount does not apply if the damage was caused intentionally.</li>
                    <li> With the exception of financial losses resulting from the provision of telecommunication services pursuant to Section 7 (2) TKV, the limitations of liability pursuant to Section 7.1 shall not apply to bodily injury, damage to health and life as well as personal injury and property damage pursuant to the Product Liability Act and for damages for which RSM Ebner Stolz is responsible due to intent or gross negligence and not for the absence of warranted characteristics. Furthermore, they shall not apply in the event of fraudulent concealment of a defect or the assumption of a quality guarantee and / or for damages that have arisen as a result of slightly negligent violation of essential contractual obligations (cardinal obligations). In this respect, RSM Ebner Stolz shall be liable without limitation in accordance with the statutory provisions.</li>
                </ol>
            </li>

            <li>
                <strong> Contract term, Termination </strong>
                <ol>
                    <li> This agreement may be terminated by either Party upon 3 days' written notice.</li>
                    <li> The right to terminate for important reasons remains unaffected.</li>
                </ol>
            </li>

            <li>
                <strong> Final Provisions</strong>
                <ol>
                    <li> This contract is subject to German substantive law. The application of the UN Convention on Contracts for the International Sale of Goods is excluded.</li>
                    <li> If the user is a merchant or has no general place of jurisdiction in Germany, Cologne shall be the exclusive place of jurisdiction for all disputes between the user and RSM Ebner Stolz.</li>
                </ol>
            </li>
        </ol>
    </div>
</div>
<div v-else-if="$i18n.locale=='pt'">
    <div class="terms-of-use">
        Platzhalter für portugiesischen Text.
</div>
</div>

</template>
<script>
export default {
    name: 'termsOfService',
};
</script>
