<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group deck>
            <b-card title="Klaus Kappmeyer"
                img-src="images/team/Kappmeyer.jpg"
                style="max-width: 300px;"
                img-top>
                <p class="card-text">
                    Leitung Finanzen, Produktion, Technik und Einkauf <br />Mitglied der Geschäftsleitung<br/><br/>
                    <a href="mailto: klaus.kappmeyer@gaffel.de">klaus.kappmeyer@gaffel.de</a><br/>
                    Tel. 0221-160 06 133
                </p>
            </b-card>
            <b-card title="Bärbel Langner"
                img-src="images/team/Langner.jpg"
                style="max-width: 300px;"
                img-top>
                <p class="card-text">
                    Operativer Einkauf<br/><br/>
                    <a href="mailto: baerbel.langner@gaffel.de">baerbel.langner@gaffel.de</a><br/>
                    Tel. +49 221 16006-131 
                </p>
            </b-card>
            <b-card title="Birgit Gostau"
                img-src="images/team/Gostau.jpg"
                style="max-width: 300px;"
                img-top>
                <p class="card-text">
                    Operativer Einkauf<br/><br/>
                    <a href="mailto: birgit.gostau@gaffel.de">birgit.gostau@gaffel.de</a><br/>
                    Tel. +49 221-16006 197
                </p>
            </b-card>
            <b-card title="André Grotstabel"
                img-src="images/team/Grotstabel.jpg"
                style="max-width: 300px;"
                img-top>
                <p class="card-text">
                    Zentrale Einkaufskoordination<br/><br/>
                    <a href="mailto: andre.grotstabel@ebnerstolz.de">andre.grotstabel@ebnerstolz.de</a><br/>
                    Tel. +49 173 250 1031
                </p>
            </b-card>
        </b-card-group>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>