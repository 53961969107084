<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #F8F8F8">
            Dashboard
        </div>
        <div class="card-body px-0" style="background-color: #F8F8F8">
            <div class="home">
                <b-card :title="$t(`dashboard.header1`)" class="mb-3 shadow" v-if="currentUser && (!currentUser.accepted_dsgvo || !currentUser.accepted_terms_of_service)">
                    <p>
                        {{ $t("dashboard.profile_note1") }} <router-link to="/app/profile" href="#">{{ $t("dashboard.profile_note2") }}</router-link>{{ $t("dashboard.profile_note3") }}
                    </p>
                    <b-button to="/app/profile" class="my-2" href="#">{{ $t("dashboard.profile_update") }}</b-button>
                    <b-alert variant="danger" :show="!currentUser.accepted_terms_of_service">
                        {{ $t("dashboard.accept_termsofservice") }}
                    </b-alert>
                    <b-alert variant="danger" :show="!currentUser.accepted_dsgvo">
                        {{ $t("dashboard.accept_dsgvo") }}
                    </b-alert>
                </b-card>
            
                <b-card :title="$t(`dashboard.actual_auctions`)" class="mb-3 shadow" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <b-card-text>
                        <br/>
                        <div class="table-scrollable">
                            <b-table striped hover :items="auctions" :fields="auctionFields">
                                <template #cell(title)="data">
                                    <router-link :to="'/app/auktionen/view/'+data.item.id" href="#">{{data.item.title}}</router-link>
                                </template>
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                    {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">
                                        -
                                    </template>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date)}}
                                </template>
                            </b-table>
                        </div>
                        <br/>
                    </b-card-text>
                </b-card>
            
                <b-card :title="$t(`dashboard.actual_callforbids`)" class="mb-3 shadow" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <b-card-text>
                        <br/>
                        <div class="table-scrollable">
                            <b-table striped hover :items="callForBids" :fields="callForBidsFields">
                                <template #cell(title)="data">
                                    <router-link :to="'/app/ausschreibung/view/'+data.item.id" href="#">{{data.item.title}}</router-link>
                                </template>
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                    {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">
                                        -
                                    </template>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date)}}
                                </template>
                            </b-table>
                        </div>
                        <br/>
                    </b-card-text>
                </b-card>
            
                <b-card :title="$t(`dashboard.card_contracts`)" class="mb-3 shadow" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <b-card-text>
                        {{ $t("dashboard.card_contracts_text") }}
                    </b-card-text>
                </b-card>

                <b-card :title="$t(`dashboard.profile`)" class="mb-3 shadow" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <br/>
                    <router-link to="/app/profile">{{ $t("dashboard.profile_update") }}</router-link>
                    <br />
                </b-card>
                <br />

                <template>
                    <div>
                    <b-button v-b-toggle.sidebar-variant v-if="hasRole('Administrator')"><b-icon icon="asterisk" />Icon Sidebar</b-button>
                    <b-sidebar id="sidebar-variant" title="Iconauswahl" bg-variant="dark" text-variant="light" width="800px" shadow>
                        <div class="px-3 py-2">
                            <template>
                                <div>
                                    <b-button-toolbar> 
                                        <b-button-group class="m-1">
                                            <b-button title="Neue Ausschreibung anlegen" to="/app/auktionen/new">
                                                <v-icon name="plus-circle" />
                                            </b-button>
                                            <b-button title="Einladungslink erstellen" to="/app/invitation/new">
                                                <v-icon name="envelope" />
                                            </b-button>
                                            <b-button title="Neuen Benutzer anlegen" to="/app/user/new">
                                                <v-icon name="user-plus" />
                                            </b-button>
                                            <b-button title="Neuen Fragebogen anlegen" to="/app/questionnaires/new">
                                                <v-icon name="question" />
                                            </b-button>
                                        </b-button-group>
                                    </b-button-toolbar>
                                    <v-icon name="file-import" title="file-import" />
                                    <v-icon name="plus-circle" title="plus-circle"/>
                                    <v-icon name="file-import" />
                                    <v-icon name="Upload" />
                                    <v-icon name="download" />
                                    <v-icon name="file-excel" />
                                    <v-icon name="gavel" />
                                    <v-icon name="toolbox" />
                                    <v-icon name="envelope" />
                                    <v-icon name="paperclip" />
                                    <v-icon name="compass" />
                                    <v-icon name="address-book" />
                                    <v-icon name="tags" />
                                    <v-icon name="address-card" />
                                    <v-icon name="bullhorn" />
                                    <v-icon name="recycle" />
                                    <v-icon name="file-signature" />
                                    <v-icon name="file-contract" />
                                    <v-icon name="ruler-combined" />
                                    <v-icon name="unlock" />
                                    <v-icon name="user-shield" />
                                    <v-icon name="dna" />
                                    <v-icon name="lock" />
                                    <v-icon name="fingerprint" />
                                    <v-icon name="wallet" />
                                    <v-icon name="percent" />
                                    <v-icon name="chart-line" />
                                    <v-icon name="coins" />
                                    <v-icon name="money-bill" />
                                    <v-icon name="list" />
                                    <v-icon name="list-ul" />
                                    <v-icon name="list-ol" />
                                    <v-icon name="question" />
                                    <v-icon name="check" />
                                    <v-icon name="clipboard-check" />
                                    <v-icon name="certificate" />
                                    <v-icon name="stamp" />
                                    <v-icon name="truck" />
                                    <v-icon name="box" />
                                    <v-icon name="handshake" />
                                    <v-icon name="eraser" />
                                    <v-icon name="table" />
                                    <v-icon name="bullseye" />
                                    <v-icon name="gear" />
                                    <v-icon name="toolbox" />
                                    <v-icon name="tool" />
                                    <v-icon name="wrench" />
                                    <v-icon name="backward" />
                                    <v-icon name="backward-step" />
                                    <v-icon name="wrench" />
                                    <v-icon name="wrench-simple" />
                                    <v-icon name="user-plus" />
                                    <v-icon name="user-check" />
                                    <v-icon name="user-gear" />
                                    <v-icon name="user-clock" />
                                    <v-icon name="user-pen" />
                                    <v-icon name="circle-user" />
                                    <v-icon name="shopping-cart" />
                                    <p></p>
                                    <v-icon name="0" title="0"  />
                                    <v-icon name="1" title="1"  />
                                    <v-icon name="2" title="2"  />
                                    <v-icon name="3" title="3"  />
                                    <v-icon name="4" title="4"  />
                                    <v-icon name="42-group" title="42-group"  />
                                    <v-icon name="5" title="5"  />
                                    <v-icon name="500px" title="500px"  />
                                    <v-icon name="6" title="6"  />
                                    <v-icon name="7" title="7"  />
                                    <v-icon name="8" title="8"  />
                                    <v-icon name="9" title="9"  />
                                    <v-icon name="a" title="a"  />
                                    <v-icon name="accessible-icon" title="accessible-icon"  />
                                    <v-icon name="accusoft" title="accusoft"  />
                                    <v-icon name="address-book" title="address-book"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="address-card" title="address-card"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="adn" title="adn"  />
                                    <v-icon name="adversal" title="adversal"  />
                                    <v-icon name="affiliatetheme" title="affiliatetheme"  />
                                    <v-icon name="airbnb" title="airbnb"  />
                                    <v-icon name="algolia" title="algolia"  />
                                    <v-icon name="align-center" title="align-center"  />
                                    <v-icon name="align-justify" title="align-justify"  />
                                    <v-icon name="align-left" title="align-left"  />
                                    <v-icon name="align-right" title="align-right"  />
                                    <v-icon name="alipay" title="alipay"  />
                                    <v-icon name="amazon" title="amazon"  />
                                    <v-icon name="amazon-pay" title="amazon-pay"  />
                                    <v-icon name="amilia" title="amilia"  />
                                    <v-icon name="anchor" title="anchor"  />
                                    <v-icon name="anchor-circle-check" title="anchor-circle-check"  />
                                    <v-icon name="anchor-circle-exclamation" title="anchor-circle-exclamation"  />
                                    <v-icon name="anchor-circle-xmark" title="anchor-circle-xmark"  />
                                    <v-icon name="anchor-lock" title="anchor-lock"  />
                                    <v-icon name="android" title="android"  />
                                    <v-icon name="angellist" title="angellist"  />
                                    <v-icon name="angle-down" title="angle-down"  />
                                    <v-icon name="angle-left" title="angle-left"  />
                                    <v-icon name="angle-right" title="angle-right"  />
                                    <v-icon name="angle-up" title="angle-up"  />
                                    <v-icon name="angles-down" title="angles-down"  />
                                    <v-icon name="angles-left" title="angles-left"  />
                                    <v-icon name="angles-right" title="angles-right"  />
                                    <v-icon name="angles-up" title="angles-up"  />
                                    <v-icon name="angrycreative" title="angrycreative"  />
                                    <v-icon name="angular" title="angular"  />
                                    <v-icon name="ankh" title="ankh"  />
                                    <v-icon name="app-store" title="app-store"  />
                                    <v-icon name="app-store-ios" title="app-store-ios"  />
                                    <v-icon name="apper" title="apper"  />
                                    <v-icon name="apple" title="apple"  />
                                    <v-icon name="apple-pay" title="apple-pay"  />
                                    <v-icon name="apple-whole" title="apple-whole"  />
                                    <v-icon name="archway" title="archway"  />
                                    <v-icon name="arrow-down" title="arrow-down"  />
                                    <v-icon name="arrow-down-1-9" title="arrow-down-1-9"  />
                                    <v-icon name="arrow-down-9-1" title="arrow-down-9-1"  />
                                    <v-icon name="arrow-down-a-z" title="arrow-down-a-z"  />
                                    <v-icon name="arrow-down-long" title="arrow-down-long"  />
                                    <v-icon name="arrow-down-short-wide" title="arrow-down-short-wide"  />
                                    <v-icon name="arrow-down-up-across-line" title="arrow-down-up-across-line"  />
                                    <v-icon name="arrow-down-up-lock" title="arrow-down-up-lock"  />
                                    <v-icon name="arrow-down-wide-short" title="arrow-down-wide-short"  />
                                    <v-icon name="arrow-down-z-a" title="arrow-down-z-a"  />
                                    <v-icon name="arrow-left" title="arrow-left"  />
                                    <v-icon name="arrow-left-long" title="arrow-left-long"  />
                                    <v-icon name="arrow-pointer" title="arrow-pointer"  />
                                    <v-icon name="arrow-right" title="arrow-right"  />
                                    <v-icon name="arrow-right-arrow-left" title="arrow-right-arrow-left"  />
                                    <v-icon name="arrow-right-from-bracket" title="arrow-right-from-bracket"  />
                                    <v-icon name="arrow-right-long" title="arrow-right-long"  />
                                    <v-icon name="arrow-right-to-bracket" title="arrow-right-to-bracket"  />
                                    <v-icon name="arrow-right-to-city" title="arrow-right-to-city"  />
                                    <v-icon name="arrow-rotate-left" title="arrow-rotate-left"  />
                                    <v-icon name="arrow-rotate-right" title="arrow-rotate-right"  />
                                    <v-icon name="arrow-trend-down" title="arrow-trend-down"  />
                                    <v-icon name="arrow-trend-up" title="arrow-trend-up"  />
                                    <v-icon name="arrow-turn-down" title="arrow-turn-down"  />
                                    <v-icon name="arrow-turn-up" title="arrow-turn-up"  />
                                    <v-icon name="arrow-up" title="arrow-up"  />
                                    <v-icon name="arrow-up-1-9" title="arrow-up-1-9"  />
                                    <v-icon name="arrow-up-9-1" title="arrow-up-9-1"  />
                                    <v-icon name="arrow-up-a-z" title="arrow-up-a-z"  />
                                    <v-icon name="arrow-up-from-bracket" title="arrow-up-from-bracket"  />
                                    <v-icon name="arrow-up-from-ground-water" title="arrow-up-from-ground-water"  />
                                    <v-icon name="arrow-up-from-water-pump" title="arrow-up-from-water-pump"  />
                                    <v-icon name="arrow-up-long" title="arrow-up-long"  />
                                    <v-icon name="arrow-up-right-dots" title="arrow-up-right-dots"  />
                                    <v-icon name="arrow-up-right-from-square" title="arrow-up-right-from-square"  />
                                    <v-icon name="arrow-up-short-wide" title="arrow-up-short-wide"  />
                                    <v-icon name="arrow-up-wide-short" title="arrow-up-wide-short"  />
                                    <v-icon name="arrow-up-z-a" title="arrow-up-z-a"  />
                                    <v-icon name="arrows-down-to-line" title="arrows-down-to-line"  />
                                    <v-icon name="arrows-down-to-people" title="arrows-down-to-people"  />
                                    <v-icon name="arrows-left-right" title="arrows-left-right"  />
                                    <v-icon name="arrows-left-right-to-line" title="arrows-left-right-to-line"  />
                                    <v-icon name="arrows-rotate" title="arrows-rotate"  />
                                    <v-icon name="arrows-spin" title="arrows-spin"  />
                                    <v-icon name="arrows-split-up-and-left" title="arrows-split-up-and-left"  />
                                    <v-icon name="arrows-to-circle" title="arrows-to-circle"  />
                                    <v-icon name="arrows-to-dot" title="arrows-to-dot"  />
                                    <v-icon name="arrows-to-eye" title="arrows-to-eye"  />
                                    <v-icon name="arrows-turn-right" title="arrows-turn-right"  />
                                    <v-icon name="arrows-turn-to-dots" title="arrows-turn-to-dots"  />
                                    <v-icon name="arrows-up-down" title="arrows-up-down"  />
                                    <v-icon name="arrows-up-down-left-right" title="arrows-up-down-left-right"  />
                                    <v-icon name="arrows-up-to-line" title="arrows-up-to-line"  />
                                    <v-icon name="artstation" title="artstation"  />
                                    <v-icon name="asterisk" title="asterisk"  />
                                    <v-icon name="asymmetrik" title="asymmetrik"  />
                                    <v-icon name="at" title="at"  />
                                    <v-icon name="atlassian" title="atlassian"  />
                                    <v-icon name="atom" title="atom"  />
                                    <v-icon name="audible" title="audible"  />
                                    <v-icon name="audio-description" title="audio-description"  />
                                    <v-icon name="austral-sign" title="austral-sign"  />
                                    <v-icon name="autoprefixer" title="autoprefixer"  />
                                    <v-icon name="avianex" title="avianex"  />
                                    <v-icon name="aviato" title="aviato"  />
                                    <v-icon name="award" title="award"  />
                                    <v-icon name="aws" title="aws"  />
                                    <v-icon name="b" title="b"  />
                                    <v-icon name="baby" title="baby"  />
                                    <v-icon name="baby-carriage" title="baby-carriage"  />
                                    <v-icon name="backward" title="backward"  />
                                    <v-icon name="backward-fast" title="backward-fast"  />
                                    <v-icon name="backward-step" title="backward-step"  />
                                    <v-icon name="bacon" title="bacon"  />
                                    <v-icon name="bacteria" title="bacteria"  />
                                    <v-icon name="bacterium" title="bacterium"  />
                                    <v-icon name="bag-shopping" title="bag-shopping"  />
                                    <v-icon name="bahai" title="bahai"  />
                                    <v-icon name="baht-sign" title="baht-sign"  />
                                    <v-icon name="ban" title="ban"  />
                                    <v-icon name="ban-smoking" title="ban-smoking"  />
                                    <v-icon name="bandage" title="bandage"  />
                                    <v-icon name="bandcamp" title="bandcamp"  />
                                    <v-icon name="barcode" title="barcode"  />
                                    <v-icon name="bars" title="bars"  />
                                    <v-icon name="bars-progress" title="bars-progress"  />
                                    <v-icon name="bars-staggered" title="bars-staggered"  />
                                    <v-icon name="baseball" title="baseball"  />
                                    <v-icon name="baseball-bat-ball" title="baseball-bat-ball"  />
                                    <v-icon name="basket-shopping" title="basket-shopping"  />
                                    <v-icon name="basketball" title="basketball"  />
                                    <v-icon name="bath" title="bath"  />
                                    <v-icon name="battery-empty" title="battery-empty"  />
                                    <v-icon name="battery-full" title="battery-full"  />
                                    <v-icon name="battery-half" title="battery-half"  />
                                    <v-icon name="battery-quarter" title="battery-quarter"  />
                                    <v-icon name="battery-three-quarters" title="battery-three-quarters"  />
                                    <v-icon name="battle-net" title="battle-net"  />
                                    <v-icon name="bed" title="bed"  />
                                    <v-icon name="bed-pulse" title="bed-pulse"  />
                                    <v-icon name="beer-mug-empty" title="beer-mug-empty"  />
                                    <v-icon name="behance" title="behance"  />
                                    <v-icon name="bell" title="bell"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="bell-concierge" title="bell-concierge"  />
                                    <v-icon name="bell-slash" title="bell-slash"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="bezier-curve" title="bezier-curve"  />
                                    <v-icon name="bicycle" title="bicycle"  />
                                    <v-icon name="bilibili" title="bilibili"  />
                                    <v-icon name="bimobject" title="bimobject"  />
                                    <v-icon name="binoculars" title="binoculars"  />
                                    <v-icon name="biohazard" title="biohazard"  />
                                    <v-icon name="bitbucket" title="bitbucket"  />
                                    <v-icon name="bitcoin" title="bitcoin"  />
                                    <v-icon name="bitcoin-sign" title="bitcoin-sign"  />
                                    <v-icon name="bity" title="bity"  />
                                    <v-icon name="black-tie" title="black-tie"  />
                                    <v-icon name="blackberry" title="blackberry"  />
                                    <v-icon name="blender" title="blender"  />
                                    <v-icon name="blender-phone" title="blender-phone"  />
                                    <v-icon name="blog" title="blog"  />
                                    <v-icon name="blogger" title="blogger"  />
                                    <v-icon name="blogger-b" title="blogger-b"  />
                                    <v-icon name="bluetooth" title="bluetooth"  />
                                    <v-icon name="bluetooth-b" title="bluetooth-b"  />
                                    <v-icon name="bold" title="bold"  />
                                    <v-icon name="bolt" title="bolt"  />
                                    <v-icon name="bolt-lightning" title="bolt-lightning"  />
                                    <v-icon name="bomb" title="bomb"  />
                                    <v-icon name="bone" title="bone"  />
                                    <v-icon name="bong" title="bong"  />
                                    <v-icon name="book" title="book"  />
                                    <v-icon name="book-atlas" title="book-atlas"  />
                                    <v-icon name="book-bible" title="book-bible"  />
                                    <v-icon name="book-bookmark" title="book-bookmark"  />
                                    <v-icon name="book-journal-whills" title="book-journal-whills"  />
                                    <v-icon name="book-medical" title="book-medical"  />
                                    <v-icon name="book-open" title="book-open"  />
                                    <v-icon name="book-open-reader" title="book-open-reader"  />
                                    <v-icon name="book-quran" title="book-quran"  />
                                    <v-icon name="book-skull" title="book-skull"  />
                                    <v-icon name="book-tanakh" title="book-tanakh"  />
                                    <v-icon name="bookmark" title="bookmark"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="bootstrap" title="bootstrap"  />
                                    <v-icon name="border-all" title="border-all"  />
                                    <v-icon name="border-none" title="border-none"  />
                                    <v-icon name="border-top-left" title="border-top-left"  />
                                    <v-icon name="bore-hole" title="bore-hole"  />
                                    <v-icon name="bots" title="bots"  />
                                    <v-icon name="bottle-droplet" title="bottle-droplet"  />
                                    <v-icon name="bottle-water" title="bottle-water"  />
                                    <v-icon name="bowl-food" title="bowl-food"  />
                                    <v-icon name="bowl-rice" title="bowl-rice"  />
                                    <v-icon name="bowling-ball" title="bowling-ball"  />
                                    <v-icon name="box" title="box"  />
                                    <v-icon name="box-archive" title="box-archive"  />
                                    <v-icon name="box-open" title="box-open"  />
                                    <v-icon name="box-tissue" title="box-tissue"  />
                                    <v-icon name="boxes-packing" title="boxes-packing"  />
                                    <v-icon name="boxes-stacked" title="boxes-stacked"  />
                                    <v-icon name="braille" title="braille"  />
                                    <v-icon name="brain" title="brain"  />
                                    <v-icon name="brazilian-real-sign" title="brazilian-real-sign"  />
                                    <v-icon name="bread-slice" title="bread-slice"  />
                                    <v-icon name="bridge" title="bridge"  />
                                    <v-icon name="bridge-circle-check" title="bridge-circle-check"  />
                                    <v-icon name="bridge-circle-exclamation" title="bridge-circle-exclamation"  />
                                    <v-icon name="bridge-circle-xmark" title="bridge-circle-xmark"  />
                                    <v-icon name="bridge-lock" title="bridge-lock"  />
                                    <v-icon name="bridge-water" title="bridge-water"  />
                                    <v-icon name="briefcase" title="briefcase"  />
                                    <v-icon name="briefcase-medical" title="briefcase-medical"  />
                                    <v-icon name="broom" title="broom"  />
                                    <v-icon name="broom-ball" title="broom-ball"  />
                                    <v-icon name="brush" title="brush"  />
                                    <v-icon name="btc" title="btc"  />
                                    <v-icon name="bucket" title="bucket"  />
                                    <v-icon name="buffer" title="buffer"  />
                                    <v-icon name="bug" title="bug"  />
                                    <v-icon name="bug-slash" title="bug-slash"  />
                                    <v-icon name="bugs" title="bugs"  />
                                    <v-icon name="building" title="building"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="building-circle-arrow-right" title="building-circle-arrow-right"  />
                                    <v-icon name="building-circle-check" title="building-circle-check"  />
                                    <v-icon name="building-circle-exclamation" title="building-circle-exclamation"  />
                                    <v-icon name="building-circle-xmark" title="building-circle-xmark"  />
                                    <v-icon name="building-columns" title="building-columns"  />
                                    <v-icon name="building-flag" title="building-flag"  />
                                    <v-icon name="building-lock" title="building-lock"  />
                                    <v-icon name="building-ngo" title="building-ngo"  />
                                    <v-icon name="building-shield" title="building-shield"  />
                                    <v-icon name="building-un" title="building-un"  />
                                    <v-icon name="building-user" title="building-user"  />
                                    <v-icon name="building-wheat" title="building-wheat"  />
                                    <v-icon name="bullhorn" title="bullhorn"  />
                                    <v-icon name="bullseye" title="bullseye"  />
                                    <v-icon name="burger" title="burger"  />
                                    <v-icon name="buromobelexperte" title="buromobelexperte"  />
                                    <v-icon name="burst" title="burst"  />
                                    <v-icon name="bus" title="bus"  />
                                    <v-icon name="bus-simple" title="bus-simple"  />
                                    <v-icon name="business-time" title="business-time"  />
                                    <v-icon name="buy-n-large" title="buy-n-large"  />
                                    <v-icon name="buysellads" title="buysellads"  />
                                    <v-icon name="c" title="c"  />
                                    <v-icon name="cable-car" title="cable-car"  />
                                    <v-icon name="cake-candles" title="cake-candles"  />
                                    <v-icon name="calculator" title="calculator"  />
                                    <v-icon name="calendar" title="calendar"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="calendar-check" title="calendar-check"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="calendar-day" title="calendar-day"  />
                                    <v-icon name="calendar-days" title="calendar-days"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="calendar-minus" title="calendar-minus"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="calendar-plus" title="calendar-plus"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="calendar-week" title="calendar-week"  />
                                    <v-icon name="calendar-xmark" title="calendar-xmark"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="camera" title="camera"  />
                                    <v-icon name="camera-retro" title="camera-retro"  />
                                    <v-icon name="camera-rotate" title="camera-rotate"  />
                                    <v-icon name="campground" title="campground"  />
                                    <v-icon name="canadian-maple-leaf" title="canadian-maple-leaf"  />
                                    <v-icon name="candy-cane" title="candy-cane"  />
                                    <v-icon name="cannabis" title="cannabis"  />
                                    <v-icon name="capsules" title="capsules"  />
                                    <v-icon name="car" title="car"  />
                                    <v-icon name="car-battery" title="car-battery"  />
                                    <v-icon name="car-burst" title="car-burst"  />
                                    <v-icon name="car-on" title="car-on"  />
                                    <v-icon name="car-rear" title="car-rear"  />
                                    <v-icon name="car-side" title="car-side"  />
                                    <v-icon name="car-tunnel" title="car-tunnel"  />
                                    <v-icon name="caravan" title="caravan"  />
                                    <v-icon name="caret-down" title="caret-down"  />
                                    <v-icon name="caret-left" title="caret-left"  />
                                    <v-icon name="caret-right" title="caret-right"  />
                                    <v-icon name="caret-up" title="caret-up"  />
                                    <v-icon name="carrot" title="carrot"  />
                                    <v-icon name="cart-arrow-down" title="cart-arrow-down"  />
                                    <v-icon name="cart-flatbed" title="cart-flatbed"  />
                                    <v-icon name="cart-flatbed-suitcase" title="cart-flatbed-suitcase"  />
                                    <v-icon name="cart-plus" title="cart-plus"  />
                                    <v-icon name="cart-shopping" title="cart-shopping"  />
                                    <v-icon name="cash-register" title="cash-register"  />
                                    <v-icon name="cat" title="cat"  />
                                    <v-icon name="cc-amazon-pay" title="cc-amazon-pay"  />
                                    <v-icon name="cc-amex" title="cc-amex"  />
                                    <v-icon name="cc-apple-pay" title="cc-apple-pay"  />
                                    <v-icon name="cc-diners-club" title="cc-diners-club"  />
                                    <v-icon name="cc-discover" title="cc-discover"  />
                                    <v-icon name="cc-jcb" title="cc-jcb"  />
                                    <v-icon name="cc-mastercard" title="cc-mastercard"  />
                                    <v-icon name="cc-paypal" title="cc-paypal"  />
                                    <v-icon name="cc-stripe" title="cc-stripe"  />
                                    <v-icon name="cc-visa" title="cc-visa"  />
                                    <v-icon name="cedi-sign" title="cedi-sign"  />
                                    <v-icon name="cent-sign" title="cent-sign"  />
                                    <v-icon name="centercode" title="centercode"  />
                                    <v-icon name="centos" title="centos"  />
                                    <v-icon name="certificate" title="certificate"  />
                                    <v-icon name="chair" title="chair"  />
                                    <v-icon name="chalkboard" title="chalkboard"  />
                                    <v-icon name="chalkboard-user" title="chalkboard-user"  />
                                    <v-icon name="champagne-glasses" title="champagne-glasses"  />
                                    <v-icon name="charging-station" title="charging-station"  />
                                    <v-icon name="chart-area" title="chart-area"  />
                                    <v-icon name="chart-bar" title="chart-bar"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="chart-column" title="chart-column"  />
                                    <v-icon name="chart-gantt" title="chart-gantt"  />
                                    <v-icon name="chart-line" title="chart-line"  />
                                    <v-icon name="chart-pie" title="chart-pie"  />
                                    <v-icon name="chart-simple" title="chart-simple"  />
                                    <v-icon name="check" title="check"  />
                                    <v-icon name="check-double" title="check-double"  />
                                    <v-icon name="check-to-slot" title="check-to-slot"  />
                                    <v-icon name="cheese" title="cheese"  />
                                    <v-icon name="chess" title="chess"  />
                                    <v-icon name="chess-bishop" title="chess-bishop"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="chess-board" title="chess-board"  />
                                    <v-icon name="chess-king" title="chess-king"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="chess-knight" title="chess-knight"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="chess-pawn" title="chess-pawn"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="chess-queen" title="chess-queen"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="chess-rook" title="chess-rook"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="chevron-down" title="chevron-down"  />
                                    <v-icon name="chevron-left" title="chevron-left"  />
                                    <v-icon name="chevron-right" title="chevron-right"  />
                                    <v-icon name="chevron-up" title="chevron-up"  />
                                    <v-icon name="child" title="child"  />
                                    <v-icon name="child-dress" title="child-dress"  />
                                    <v-icon name="child-reaching" title="child-reaching"  />
                                    <v-icon name="child-rifle" title="child-rifle"  />
                                    <v-icon name="children" title="children"  />
                                    <v-icon name="chrome" title="chrome"  />
                                    <v-icon name="chromecast" title="chromecast"  />
                                    <v-icon name="church" title="church"  />
                                    <v-icon name="circle" title="circle"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-arrow-down" title="circle-arrow-down"  />
                                    <v-icon name="circle-arrow-left" title="circle-arrow-left"  />
                                    <v-icon name="circle-arrow-right" title="circle-arrow-right"  />
                                    <v-icon name="circle-arrow-up" title="circle-arrow-up"  />
                                    <v-icon name="circle-check" title="circle-check"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-chevron-down" title="circle-chevron-down"  />
                                    <v-icon name="circle-chevron-left" title="circle-chevron-left"  />
                                    <v-icon name="circle-chevron-right" title="circle-chevron-right"  />
                                    <v-icon name="circle-chevron-up" title="circle-chevron-up"  />
                                    <v-icon name="circle-dollar-to-slot" title="circle-dollar-to-slot"  />
                                    <v-icon name="circle-dot" title="circle-dot"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-down" title="circle-down"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-exclamation" title="circle-exclamation"  />
                                    <v-icon name="circle-h" title="circle-h"  />
                                    <v-icon name="circle-half-stroke" title="circle-half-stroke"  />
                                    <v-icon name="circle-info" title="circle-info"  />
                                    <v-icon name="circle-left" title="circle-left"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-minus" title="circle-minus"  />
                                    <v-icon name="circle-nodes" title="circle-nodes"  />
                                    <v-icon name="circle-notch" title="circle-notch"  />
                                    <v-icon name="circle-pause" title="circle-pause"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-play" title="circle-play"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-plus" title="circle-plus"  />
                                    <v-icon name="circle-question" title="circle-question"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-radiation" title="circle-radiation"  />
                                    <v-icon name="circle-right" title="circle-right"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-stop" title="circle-stop"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-up" title="circle-up"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-user" title="circle-user"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="circle-xmark" title="circle-xmark"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="city" title="city"  />
                                    <v-icon name="clapperboard" title="clapperboard"  />
                                    <v-icon name="clipboard" title="clipboard"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="clipboard-check" title="clipboard-check"  />
                                    <v-icon name="clipboard-list" title="clipboard-list"  />
                                    <v-icon name="clipboard-question" title="clipboard-question"  />
                                    <v-icon name="clipboard-user" title="clipboard-user"  />
                                    <v-icon name="clock" title="clock"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="clock-rotate-left" title="clock-rotate-left"  />
                                    <v-icon name="clone" title="clone"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="closed-captioning" title="closed-captioning"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="cloud" title="cloud"  />
                                    <v-icon name="cloud-arrow-down" title="cloud-arrow-down"  />
                                    <v-icon name="cloud-arrow-up" title="cloud-arrow-up"  />
                                    <v-icon name="cloud-bolt" title="cloud-bolt"  />
                                    <v-icon name="cloud-meatball" title="cloud-meatball"  />
                                    <v-icon name="cloud-moon" title="cloud-moon"  />
                                    <v-icon name="cloud-moon-rain" title="cloud-moon-rain"  />
                                    <v-icon name="cloud-rain" title="cloud-rain"  />
                                    <v-icon name="cloud-showers-heavy" title="cloud-showers-heavy"  />
                                    <v-icon name="cloud-showers-water" title="cloud-showers-water"  />
                                    <v-icon name="cloud-sun" title="cloud-sun"  />
                                    <v-icon name="cloud-sun-rain" title="cloud-sun-rain"  />
                                    <v-icon name="cloudflare" title="cloudflare"  />
                                    <v-icon name="cloudscale" title="cloudscale"  />
                                    <v-icon name="cloudsmith" title="cloudsmith"  />
                                    <v-icon name="cloudversify" title="cloudversify"  />
                                    <v-icon name="clover" title="clover"  />
                                    <v-icon name="cmplid" title="cmplid"  />
                                    <v-icon name="code" title="code"  />
                                    <v-icon name="code-branch" title="code-branch"  />
                                    <v-icon name="code-commit" title="code-commit"  />
                                    <v-icon name="code-compare" title="code-compare"  />
                                    <v-icon name="code-fork" title="code-fork"  />
                                    <v-icon name="code-merge" title="code-merge"  />
                                    <v-icon name="code-pull-request" title="code-pull-request"  />
                                    <v-icon name="codepen" title="codepen"  />
                                    <v-icon name="codiepie" title="codiepie"  />
                                    <v-icon name="coins" title="coins"  />
                                    <v-icon name="colon-sign" title="colon-sign"  />
                                    <v-icon name="comment" title="comment"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="comment-dollar" title="comment-dollar"  />
                                    <v-icon name="comment-dots" title="comment-dots"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="comment-medical" title="comment-medical"  />
                                    <v-icon name="comment-slash" title="comment-slash"  />
                                    <v-icon name="comment-sms" title="comment-sms"  />
                                    <v-icon name="comments" title="comments"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="comments-dollar" title="comments-dollar"  />
                                    <v-icon name="compact-disc" title="compact-disc"  />
                                    <v-icon name="compass" title="compass"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="compass-drafting" title="compass-drafting"  />
                                    <v-icon name="compress" title="compress"  />
                                    <v-icon name="computer" title="computer"  />
                                    <v-icon name="computer-mouse" title="computer-mouse"  />
                                    <v-icon name="confluence" title="confluence"  />
                                    <v-icon name="connectdevelop" title="connectdevelop"  />
                                    <v-icon name="contao" title="contao"  />
                                    <v-icon name="cookie" title="cookie"  />
                                    <v-icon name="cookie-bite" title="cookie-bite"  />
                                    <v-icon name="copy" title="copy"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="copyright" title="copyright"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="cotton-bureau" title="cotton-bureau"  />
                                    <v-icon name="couch" title="couch"  />
                                    <v-icon name="cow" title="cow"  />
                                    <v-icon name="cpanel" title="cpanel"  />
                                    <v-icon name="creative-commons" title="creative-commons"  />
                                    <v-icon name="creative-commons-by" title="creative-commons-by"  />
                                    <v-icon name="creative-commons-nc" title="creative-commons-nc"  />
                                    <v-icon name="creative-commons-nc-eu" title="creative-commons-nc-eu"  />
                                    <v-icon name="creative-commons-nc-jp" title="creative-commons-nc-jp"  />
                                    <v-icon name="creative-commons-nd" title="creative-commons-nd"  />
                                    <v-icon name="creative-commons-pd" title="creative-commons-pd"  />
                                    <v-icon name="creative-commons-pd-alt" title="creative-commons-pd-alt"  />
                                    <v-icon name="creative-commons-remix" title="creative-commons-remix"  />
                                    <v-icon name="creative-commons-sa" title="creative-commons-sa"  />
                                    <v-icon name="creative-commons-sampling" title="creative-commons-sampling"  />
                                    <v-icon name="creative-commons-sampling-plus" title="creative-commons-sampling-plus"  />
                                    <v-icon name="creative-commons-share" title="creative-commons-share"  />
                                    <v-icon name="creative-commons-zero" title="creative-commons-zero"  />
                                    <v-icon name="credit-card" title="credit-card"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="critical-role" title="critical-role"  />
                                    <v-icon name="crop" title="crop"  />
                                    <v-icon name="crop-simple" title="crop-simple"  />
                                    <v-icon name="cross" title="cross"  />
                                    <v-icon name="crosshairs" title="crosshairs"  />
                                    <v-icon name="crow" title="crow"  />
                                    <v-icon name="crown" title="crown"  />
                                    <v-icon name="crutch" title="crutch"  />
                                    <v-icon name="cruzeiro-sign" title="cruzeiro-sign"  />
                                    <v-icon name="css3" title="css3"  />
                                    <v-icon name="css3-alt" title="css3-alt"  />
                                    <v-icon name="cube" title="cube"  />
                                    <v-icon name="cubes" title="cubes"  />
                                    <v-icon name="cubes-stacked" title="cubes-stacked"  />
                                    <v-icon name="cuttlefish" title="cuttlefish"  />
                                    <v-icon name="d" title="d"  />
                                    <v-icon name="d-and-d" title="d-and-d"  />
                                    <v-icon name="d-and-d-beyond" title="d-and-d-beyond"  />
                                    <v-icon name="dailymotion" title="dailymotion"  />
                                    <v-icon name="dashcube" title="dashcube"  />
                                    <v-icon name="database" title="database"  />
                                    <v-icon name="deezer" title="deezer"  />
                                    <v-icon name="delete-left" title="delete-left"  />
                                    <v-icon name="delicious" title="delicious"  />
                                    <v-icon name="democrat" title="democrat"  />
                                    <v-icon name="deploydog" title="deploydog"  />
                                    <v-icon name="deskpro" title="deskpro"  />
                                    <v-icon name="desktop" title="desktop"  />
                                    <v-icon name="dev" title="dev"  />
                                    <v-icon name="deviantart" title="deviantart"  />
                                    <v-icon name="dharmachakra" title="dharmachakra"  />
                                    <v-icon name="dhl" title="dhl"  />
                                    <v-icon name="diagram-next" title="diagram-next"  />
                                    <v-icon name="diagram-predecessor" title="diagram-predecessor"  />
                                    <v-icon name="diagram-project" title="diagram-project"  />
                                    <v-icon name="diagram-successor" title="diagram-successor"  />
                                    <v-icon name="diamond" title="diamond"  />
                                    <v-icon name="diamond-turn-right" title="diamond-turn-right"  />
                                    <v-icon name="diaspora" title="diaspora"  />
                                    <v-icon name="dice" title="dice"  />
                                    <v-icon name="dice-d20" title="dice-d20"  />
                                    <v-icon name="dice-d6" title="dice-d6"  />
                                    <v-icon name="dice-five" title="dice-five"  />
                                    <v-icon name="dice-four" title="dice-four"  />
                                    <v-icon name="dice-one" title="dice-one"  />
                                    <v-icon name="dice-six" title="dice-six"  />
                                    <v-icon name="dice-three" title="dice-three"  />
                                    <v-icon name="dice-two" title="dice-two"  />
                                    <v-icon name="digg" title="digg"  />
                                    <v-icon name="digital-ocean" title="digital-ocean"  />
                                    <v-icon name="discord" title="discord"  />
                                    <v-icon name="discourse" title="discourse"  />
                                    <v-icon name="disease" title="disease"  />
                                    <v-icon name="display" title="display"  />
                                    <v-icon name="divide" title="divide"  />
                                    <v-icon name="dna" title="dna"  />
                                    <v-icon name="dochub" title="dochub"  />
                                    <v-icon name="docker" title="docker"  />
                                    <v-icon name="dog" title="dog"  />
                                    <v-icon name="dollar-sign" title="dollar-sign"  />
                                    <v-icon name="dolly" title="dolly"  />
                                    <v-icon name="dong-sign" title="dong-sign"  />
                                    <v-icon name="door-closed" title="door-closed"  />
                                    <v-icon name="door-open" title="door-open"  />
                                    <v-icon name="dove" title="dove"  />
                                    <v-icon name="down-left-and-up-right-to-center" title="down-left-and-up-right-to-center"  />
                                    <v-icon name="down-long" title="down-long"  />
                                    <v-icon name="download" title="download"  />
                                    <v-icon name="draft2digital" title="draft2digital"  />
                                    <v-icon name="dragon" title="dragon"  />
                                    <v-icon name="draw-polygon" title="draw-polygon"  />
                                    <v-icon name="dribbble" title="dribbble"  />
                                    <v-icon name="dropbox" title="dropbox"  />
                                    <v-icon name="droplet" title="droplet"  />
                                    <v-icon name="droplet-slash" title="droplet-slash"  />
                                    <v-icon name="drum" title="drum"  />
                                    <v-icon name="drum-steelpan" title="drum-steelpan"  />
                                    <v-icon name="drumstick-bite" title="drumstick-bite"  />
                                    <v-icon name="drupal" title="drupal"  />
                                    <v-icon name="dumbbell" title="dumbbell"  />
                                    <v-icon name="dumpster" title="dumpster"  />
                                    <v-icon name="dumpster-fire" title="dumpster-fire"  />
                                    <v-icon name="dungeon" title="dungeon"  />
                                    <v-icon name="dyalog" title="dyalog"  />
                                    <v-icon name="e" title="e"  />
                                    <v-icon name="ear-deaf" title="ear-deaf"  />
                                    <v-icon name="ear-listen" title="ear-listen"  />
                                    <v-icon name="earlybirds" title="earlybirds"  />
                                    <v-icon name="earth-africa" title="earth-africa"  />
                                    <v-icon name="earth-americas" title="earth-americas"  />
                                    <v-icon name="earth-asia" title="earth-asia"  />
                                    <v-icon name="earth-europe" title="earth-europe"  />
                                    <v-icon name="earth-oceania" title="earth-oceania"  />
                                    <v-icon name="ebay" title="ebay"  />
                                    <v-icon name="edge" title="edge"  />
                                    <v-icon name="edge-legacy" title="edge-legacy"  />
                                    <v-icon name="egg" title="egg"  />
                                    <v-icon name="eject" title="eject"  />
                                    <v-icon name="elementor" title="elementor"  />
                                    <v-icon name="elevator" title="elevator"  />
                                    <v-icon name="ellipsis" title="ellipsis"  />
                                    <v-icon name="ellipsis-vertical" title="ellipsis-vertical"  />
                                    <v-icon name="ello" title="ello"  />
                                    <v-icon name="ember" title="ember"  />
                                    <v-icon name="empire" title="empire"  />
                                    <v-icon name="envelope" title="envelope"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="envelope-circle-check" title="envelope-circle-check"  />
                                    <v-icon name="envelope-open" title="envelope-open"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="envelope-open-text" title="envelope-open-text"  />
                                    <v-icon name="envelopes-bulk" title="envelopes-bulk"  />
                                    <v-icon name="envira" title="envira"  />
                                    <v-icon name="equals" title="equals"  />
                                    <v-icon name="eraser" title="eraser"  />
                                    <v-icon name="erlang" title="erlang"  />
                                    <v-icon name="ethereum" title="ethereum"  />
                                    <v-icon name="ethernet" title="ethernet"  />
                                    <v-icon name="etsy" title="etsy"  />
                                    <v-icon name="euro-sign" title="euro-sign"  />
                                    <v-icon name="evernote" title="evernote"  />
                                    <v-icon name="exclamation" title="exclamation"  />
                                    <v-icon name="expand" title="expand"  />
                                    <v-icon name="expeditedssl" title="expeditedssl"  />
                                    <v-icon name="explosion" title="explosion"  />
                                    <v-icon name="eye" title="eye"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="eye-dropper" title="eye-dropper"  />
                                    <v-icon name="eye-low-vision" title="eye-low-vision"  />
                                    <v-icon name="eye-slash" title="eye-slash"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="f" title="f"  />
                                    <v-icon name="face-angry" title="face-angry"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-dizzy" title="face-dizzy"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-flushed" title="face-flushed"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-frown" title="face-frown"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-frown-open" title="face-frown-open"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grimace" title="face-grimace"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin" title="face-grin"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-beam" title="face-grin-beam"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-beam-sweat" title="face-grin-beam-sweat"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-hearts" title="face-grin-hearts"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-squint" title="face-grin-squint"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-squint-tears" title="face-grin-squint-tears"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-stars" title="face-grin-stars"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-tears" title="face-grin-tears"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-tongue" title="face-grin-tongue"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-tongue-squint" title="face-grin-tongue-squint"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-tongue-wink" title="face-grin-tongue-wink"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-wide" title="face-grin-wide"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-grin-wink" title="face-grin-wink"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-kiss" title="face-kiss"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-kiss-beam" title="face-kiss-beam"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-kiss-wink-heart" title="face-kiss-wink-heart"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-laugh" title="face-laugh"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-laugh-beam" title="face-laugh-beam"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-laugh-squint" title="face-laugh-squint"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-laugh-wink" title="face-laugh-wink"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-meh" title="face-meh"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-meh-blank" title="face-meh-blank"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-rolling-eyes" title="face-rolling-eyes"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-sad-cry" title="face-sad-cry"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-sad-tear" title="face-sad-tear"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-smile" title="face-smile"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-smile-beam" title="face-smile-beam"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-smile-wink" title="face-smile-wink"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-surprise" title="face-surprise"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="face-tired" title="face-tired"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="facebook" title="facebook"  />
                                    <v-icon name="facebook-f" title="facebook-f"  />
                                    <v-icon name="facebook-messenger" title="facebook-messenger"  />
                                    <v-icon name="fan" title="fan"  />
                                    <v-icon name="fantasy-flight-games" title="fantasy-flight-games"  />
                                    <v-icon name="faucet" title="faucet"  />
                                    <v-icon name="faucet-drip" title="faucet-drip"  />
                                    <v-icon name="fax" title="fax"  />
                                    <v-icon name="feather" title="feather"  />
                                    <v-icon name="feather-pointed" title="feather-pointed"  />
                                    <v-icon name="fedex" title="fedex"  />
                                    <v-icon name="fedora" title="fedora"  />
                                    <v-icon name="ferry" title="ferry"  />
                                    <v-icon name="figma" title="figma"  />
                                    <v-icon name="file" title="file"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-arrow-down" title="file-arrow-down"  />
                                    <v-icon name="file-arrow-up" title="file-arrow-up"  />
                                    <v-icon name="file-audio" title="file-audio"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-circle-check" title="file-circle-check"  />
                                    <v-icon name="file-circle-exclamation" title="file-circle-exclamation"  />
                                    <v-icon name="file-circle-minus" title="file-circle-minus"  />
                                    <v-icon name="file-circle-plus" title="file-circle-plus"  />
                                    <v-icon name="file-circle-question" title="file-circle-question"  />
                                    <v-icon name="file-circle-xmark" title="file-circle-xmark"  />
                                    <v-icon name="file-code" title="file-code"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-contract" title="file-contract"  />
                                    <v-icon name="file-csv" title="file-csv"  />
                                    <v-icon name="file-excel" title="file-excel"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-export" title="file-export"  />
                                    <v-icon name="file-image" title="file-image"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-import" title="file-import"  />
                                    <v-icon name="file-invoice" title="file-invoice"  />
                                    <v-icon name="file-invoice-dollar" title="file-invoice-dollar"  />
                                    <v-icon name="file-lines" title="file-lines"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-medical" title="file-medical"  />
                                    <v-icon name="file-pdf" title="file-pdf"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-pen" title="file-pen"  />
                                    <v-icon name="file-powerpoint" title="file-powerpoint"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-prescription" title="file-prescription"  />
                                    <v-icon name="file-shield" title="file-shield"  />
                                    <v-icon name="file-signature" title="file-signature"  />
                                    <v-icon name="file-video" title="file-video"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-waveform" title="file-waveform"  />
                                    <v-icon name="file-word" title="file-word"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="file-zipper" title="file-zipper"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="fill" title="fill"  />
                                    <v-icon name="fill-drip" title="fill-drip"  />
                                    <v-icon name="film" title="film"  />
                                    <v-icon name="filter" title="filter"  />
                                    <v-icon name="filter-circle-dollar" title="filter-circle-dollar"  />
                                    <v-icon name="filter-circle-xmark" title="filter-circle-xmark"  />
                                    <v-icon name="fingerprint" title="fingerprint"  />
                                    <v-icon name="fire" title="fire"  />
                                    <v-icon name="fire-burner" title="fire-burner"  />
                                    <v-icon name="fire-extinguisher" title="fire-extinguisher"  />
                                    <v-icon name="fire-flame-curved" title="fire-flame-curved"  />
                                    <v-icon name="fire-flame-simple" title="fire-flame-simple"  />
                                    <v-icon name="firefox" title="firefox"  />
                                    <v-icon name="firefox-browser" title="firefox-browser"  />
                                    <v-icon name="first-order" title="first-order"  />
                                    <v-icon name="first-order-alt" title="first-order-alt"  />
                                    <v-icon name="firstdraft" title="firstdraft"  />
                                    <v-icon name="fish" title="fish"  />
                                    <v-icon name="fish-fins" title="fish-fins"  />
                                    <v-icon name="flag" title="flag"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="flag-checkered" title="flag-checkered"  />
                                    <v-icon name="flag-usa" title="flag-usa"  />
                                    <v-icon name="flask" title="flask"  />
                                    <v-icon name="flask-vial" title="flask-vial"  />
                                    <v-icon name="flickr" title="flickr"  />
                                    <v-icon name="flipboard" title="flipboard"  />
                                    <v-icon name="floppy-disk" title="floppy-disk"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="florin-sign" title="florin-sign"  />
                                    <v-icon name="fly" title="fly"  />
                                    <v-icon name="folder" title="folder"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="folder-closed" title="folder-closed"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="folder-minus" title="folder-minus"  />
                                    <v-icon name="folder-open" title="folder-open"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="folder-plus" title="folder-plus"  />
                                    <v-icon name="folder-tree" title="folder-tree"  />
                                    <v-icon name="font" title="font"  />
                                    <v-icon name="font-awesome" title="font-awesome"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="font-awesome" title="font-awesome"  />
                                    <v-icon name="fonticons" title="fonticons"  />
                                    <v-icon name="fonticons-fi" title="fonticons-fi"  />
                                    <v-icon name="football" title="football"  />
                                    <v-icon name="fort-awesome" title="fort-awesome"  />
                                    <v-icon name="fort-awesome-alt" title="fort-awesome-alt"  />
                                    <v-icon name="forumbee" title="forumbee"  />
                                    <v-icon name="forward" title="forward"  />
                                    <v-icon name="forward-fast" title="forward-fast"  />
                                    <v-icon name="forward-step" title="forward-step"  />
                                    <v-icon name="foursquare" title="foursquare"  />
                                    <v-icon name="franc-sign" title="franc-sign"  />
                                    <v-icon name="free-code-camp" title="free-code-camp"  />
                                    <v-icon name="freebsd" title="freebsd"  />
                                    <v-icon name="frog" title="frog"  />
                                    <v-icon name="fulcrum" title="fulcrum"  />
                                    <v-icon name="futbol" title="futbol"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="g" title="g"  />
                                    <v-icon name="galactic-republic" title="galactic-republic"  />
                                    <v-icon name="galactic-senate" title="galactic-senate"  />
                                    <v-icon name="gamepad" title="gamepad"  />
                                    <v-icon name="gas-pump" title="gas-pump"  />
                                    <v-icon name="gauge" title="gauge"  />
                                    <v-icon name="gauge-high" title="gauge-high"  />
                                    <v-icon name="gauge-simple" title="gauge-simple"  />
                                    <v-icon name="gauge-simple-high" title="gauge-simple-high"  />
                                    <v-icon name="gavel" title="gavel"  />
                                    <v-icon name="gear" title="gear"  />
                                    <v-icon name="gears" title="gears"  />
                                    <v-icon name="gem" title="gem"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="genderless" title="genderless"  />
                                    <v-icon name="get-pocket" title="get-pocket"  />
                                    <v-icon name="gg" title="gg"  />
                                    <v-icon name="gg-circle" title="gg-circle"  />
                                    <v-icon name="ghost" title="ghost"  />
                                    <v-icon name="gift" title="gift"  />
                                    <v-icon name="gifts" title="gifts"  />
                                    <v-icon name="git" title="git"  />
                                    <v-icon name="git-alt" title="git-alt"  />
                                    <v-icon name="github" title="github"  />
                                    <v-icon name="github-alt" title="github-alt"  />
                                    <v-icon name="gitkraken" title="gitkraken"  />
                                    <v-icon name="gitlab" title="gitlab"  />
                                    <v-icon name="gitter" title="gitter"  />
                                    <v-icon name="glass-water" title="glass-water"  />
                                    <v-icon name="glass-water-droplet" title="glass-water-droplet"  />
                                    <v-icon name="glasses" title="glasses"  />
                                    <v-icon name="glide" title="glide"  />
                                    <v-icon name="glide-g" title="glide-g"  />
                                    <v-icon name="globe" title="globe"  />
                                    <v-icon name="gofore" title="gofore"  />
                                    <v-icon name="golang" title="golang"  />
                                    <v-icon name="golf-ball-tee" title="golf-ball-tee"  />
                                    <v-icon name="goodreads" title="goodreads"  />
                                    <v-icon name="goodreads-g" title="goodreads-g"  />
                                    <v-icon name="google" title="google"  />
                                    <v-icon name="google-drive" title="google-drive"  />
                                    <v-icon name="google-pay" title="google-pay"  />
                                    <v-icon name="google-play" title="google-play"  />
                                    <v-icon name="google-plus" title="google-plus"  />
                                    <v-icon name="google-plus-g" title="google-plus-g"  />
                                    <v-icon name="google-wallet" title="google-wallet"  />
                                    <v-icon name="gopuram" title="gopuram"  />
                                    <v-icon name="graduation-cap" title="graduation-cap"  />
                                    <v-icon name="gratipay" title="gratipay"  />
                                    <v-icon name="grav" title="grav"  />
                                    <v-icon name="greater-than" title="greater-than"  />
                                    <v-icon name="greater-than-equal" title="greater-than-equal"  />
                                    <v-icon name="grip" title="grip"  />
                                    <v-icon name="grip-lines" title="grip-lines"  />
                                    <v-icon name="grip-lines-vertical" title="grip-lines-vertical"  />
                                    <v-icon name="grip-vertical" title="grip-vertical"  />
                                    <v-icon name="gripfire" title="gripfire"  />
                                    <v-icon name="group-arrows-rotate" title="group-arrows-rotate"  />
                                    <v-icon name="grunt" title="grunt"  />
                                    <v-icon name="guarani-sign" title="guarani-sign"  />
                                    <v-icon name="guilded" title="guilded"  />
                                    <v-icon name="guitar" title="guitar"  />
                                    <v-icon name="gulp" title="gulp"  />
                                    <v-icon name="gun" title="gun"  />
                                    <v-icon name="h" title="h"  />
                                    <v-icon name="hacker-news" title="hacker-news"  />
                                    <v-icon name="hackerrank" title="hackerrank"  />
                                    <v-icon name="hammer" title="hammer"  />
                                    <v-icon name="hamsa" title="hamsa"  />
                                    <v-icon name="hand" title="hand"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-back-fist" title="hand-back-fist"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-dots" title="hand-dots"  />
                                    <v-icon name="hand-fist" title="hand-fist"  />
                                    <v-icon name="hand-holding" title="hand-holding"  />
                                    <v-icon name="hand-holding-dollar" title="hand-holding-dollar"  />
                                    <v-icon name="hand-holding-droplet" title="hand-holding-droplet"  />
                                    <v-icon name="hand-holding-hand" title="hand-holding-hand"  />
                                    <v-icon name="hand-holding-heart" title="hand-holding-heart"  />
                                    <v-icon name="hand-holding-medical" title="hand-holding-medical"  />
                                    <v-icon name="hand-lizard" title="hand-lizard"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-middle-finger" title="hand-middle-finger"  />
                                    <v-icon name="hand-peace" title="hand-peace"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-point-down" title="hand-point-down"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-point-left" title="hand-point-left"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-point-right" title="hand-point-right"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-point-up" title="hand-point-up"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-pointer" title="hand-pointer"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-scissors" title="hand-scissors"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hand-sparkles" title="hand-sparkles"  />
                                    <v-icon name="hand-spock" title="hand-spock"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="handcuffs" title="handcuffs"  />
                                    <v-icon name="hands" title="hands"  />
                                    <v-icon name="hands-asl-interpreting" title="hands-asl-interpreting"  />
                                    <v-icon name="hands-bound" title="hands-bound"  />
                                    <v-icon name="hands-bubbles" title="hands-bubbles"  />
                                    <v-icon name="hands-clapping" title="hands-clapping"  />
                                    <v-icon name="hands-holding" title="hands-holding"  />
                                    <v-icon name="hands-holding-child" title="hands-holding-child"  />
                                    <v-icon name="hands-holding-circle" title="hands-holding-circle"  />
                                    <v-icon name="hands-praying" title="hands-praying"  />
                                    <v-icon name="handshake" title="handshake"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="handshake-angle" title="handshake-angle"  />
                                    <v-icon name="handshake-simple" title="handshake-simple"  />
                                    <v-icon name="handshake-simple-slash" title="handshake-simple-slash"  />
                                    <v-icon name="handshake-slash" title="handshake-slash"  />
                                    <v-icon name="hanukiah" title="hanukiah"  />
                                    <v-icon name="hard-drive" title="hard-drive"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hashnode" title="hashnode"  />
                                    <v-icon name="hashtag" title="hashtag"  />
                                    <v-icon name="hat-cowboy" title="hat-cowboy"  />
                                    <v-icon name="hat-cowboy-side" title="hat-cowboy-side"  />
                                    <v-icon name="hat-wizard" title="hat-wizard"  />
                                    <v-icon name="head-side-cough" title="head-side-cough"  />
                                    <v-icon name="head-side-cough-slash" title="head-side-cough-slash"  />
                                    <v-icon name="head-side-mask" title="head-side-mask"  />
                                    <v-icon name="head-side-virus" title="head-side-virus"  />
                                    <v-icon name="heading" title="heading"  />
                                    <v-icon name="headphones" title="headphones"  />
                                    <v-icon name="headphones-simple" title="headphones-simple"  />
                                    <v-icon name="headset" title="headset"  />
                                    <v-icon name="heart" title="heart"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="heart-circle-bolt" title="heart-circle-bolt"  />
                                    <v-icon name="heart-circle-check" title="heart-circle-check"  />
                                    <v-icon name="heart-circle-exclamation" title="heart-circle-exclamation"  />
                                    <v-icon name="heart-circle-minus" title="heart-circle-minus"  />
                                    <v-icon name="heart-circle-plus" title="heart-circle-plus"  />
                                    <v-icon name="heart-circle-xmark" title="heart-circle-xmark"  />
                                    <v-icon name="heart-crack" title="heart-crack"  />
                                    <v-icon name="heart-pulse" title="heart-pulse"  />
                                    <v-icon name="helicopter" title="helicopter"  />
                                    <v-icon name="helicopter-symbol" title="helicopter-symbol"  />
                                    <v-icon name="helmet-safety" title="helmet-safety"  />
                                    <v-icon name="helmet-un" title="helmet-un"  />
                                    <v-icon name="highlighter" title="highlighter"  />
                                    <v-icon name="hill-avalanche" title="hill-avalanche"  />
                                    <v-icon name="hill-rockslide" title="hill-rockslide"  />
                                    <v-icon name="hippo" title="hippo"  />
                                    <v-icon name="hips" title="hips"  />
                                    <v-icon name="hire-a-helper" title="hire-a-helper"  />
                                    <v-icon name="hive" title="hive"  />
                                    <v-icon name="hockey-puck" title="hockey-puck"  />
                                    <v-icon name="holly-berry" title="holly-berry"  />
                                    <v-icon name="hooli" title="hooli"  />
                                    <v-icon name="hornbill" title="hornbill"  />
                                    <v-icon name="horse" title="horse"  />
                                    <v-icon name="horse-head" title="horse-head"  />
                                    <v-icon name="hospital" title="hospital"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hospital-user" title="hospital-user"  />
                                    <v-icon name="hot-tub-person" title="hot-tub-person"  />
                                    <v-icon name="hotdog" title="hotdog"  />
                                    <v-icon name="hotel" title="hotel"  />
                                    <v-icon name="hotjar" title="hotjar"  />
                                    <v-icon name="hourglass" title="hourglass"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hourglass-end" title="hourglass-end"  />
                                    <v-icon name="hourglass-half" title="hourglass-half"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="hourglass-start" title="hourglass-start"  />
                                    <v-icon name="house" title="house"  />
                                    <v-icon name="house-chimney" title="house-chimney"  />
                                    <v-icon name="house-chimney-crack" title="house-chimney-crack"  />
                                    <v-icon name="house-chimney-medical" title="house-chimney-medical"  />
                                    <v-icon name="house-chimney-user" title="house-chimney-user"  />
                                    <v-icon name="house-chimney-window" title="house-chimney-window"  />
                                    <v-icon name="house-circle-check" title="house-circle-check"  />
                                    <v-icon name="house-circle-exclamation" title="house-circle-exclamation"  />
                                    <v-icon name="house-circle-xmark" title="house-circle-xmark"  />
                                    <v-icon name="house-crack" title="house-crack"  />
                                    <v-icon name="house-fire" title="house-fire"  />
                                    <v-icon name="house-flag" title="house-flag"  />
                                    <v-icon name="house-flood-water" title="house-flood-water"  />
                                    <v-icon name="house-flood-water-circle-arrow-right" title="house-flood-water-circle-arrow-right"  />
                                    <v-icon name="house-laptop" title="house-laptop"  />
                                    <v-icon name="house-lock" title="house-lock"  />
                                    <v-icon name="house-medical" title="house-medical"  />
                                    <v-icon name="house-medical-circle-check" title="house-medical-circle-check"  />
                                    <v-icon name="house-medical-circle-exclamation" title="house-medical-circle-exclamation"  />
                                    <v-icon name="house-medical-circle-xmark" title="house-medical-circle-xmark"  />
                                    <v-icon name="house-medical-flag" title="house-medical-flag"  />
                                    <v-icon name="house-signal" title="house-signal"  />
                                    <v-icon name="house-tsunami" title="house-tsunami"  />
                                    <v-icon name="house-user" title="house-user"  />
                                    <v-icon name="houzz" title="houzz"  />
                                    <v-icon name="hryvnia-sign" title="hryvnia-sign"  />
                                    <v-icon name="html5" title="html5"  />
                                    <v-icon name="hubspot" title="hubspot"  />
                                    <v-icon name="hurricane" title="hurricane"  />
                                    <v-icon name="i" title="i"  />
                                    <v-icon name="i-cursor" title="i-cursor"  />
                                    <v-icon name="ice-cream" title="ice-cream"  />
                                    <v-icon name="icicles" title="icicles"  />
                                    <v-icon name="icons" title="icons"  />
                                    <v-icon name="id-badge" title="id-badge"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="id-card" title="id-card"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="id-card-clip" title="id-card-clip"  />
                                    <v-icon name="ideal" title="ideal"  />
                                    <v-icon name="igloo" title="igloo"  />
                                    <v-icon name="image" title="image"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="image-portrait" title="image-portrait"  />
                                    <v-icon name="images" title="images"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="imdb" title="imdb"  />
                                    <v-icon name="inbox" title="inbox"  />
                                    <v-icon name="indent" title="indent"  />
                                    <v-icon name="indian-rupee-sign" title="indian-rupee-sign"  />
                                    <v-icon name="industry" title="industry"  />
                                    <v-icon name="infinity" title="infinity"  />
                                    <v-icon name="info" title="info"  />
                                    <v-icon name="instagram" title="instagram"  />
                                    <v-icon name="instalod" title="instalod"  />
                                    <v-icon name="intercom" title="intercom"  />
                                    <v-icon name="internet-explorer" title="internet-explorer"  />
                                    <v-icon name="invision" title="invision"  />
                                    <v-icon name="ioxhost" title="ioxhost"  />
                                    <v-icon name="italic" title="italic"  />
                                    <v-icon name="itch-io" title="itch-io"  />
                                    <v-icon name="itunes" title="itunes"  />
                                    <v-icon name="itunes-note" title="itunes-note"  />
                                    <v-icon name="j" title="j"  />
                                    <v-icon name="jar" title="jar"  />
                                    <v-icon name="jar-wheat" title="jar-wheat"  />
                                    <v-icon name="java" title="java"  />
                                    <v-icon name="jedi" title="jedi"  />
                                    <v-icon name="jedi-order" title="jedi-order"  />
                                    <v-icon name="jenkins" title="jenkins"  />
                                    <v-icon name="jet-fighter" title="jet-fighter"  />
                                    <v-icon name="jet-fighter-up" title="jet-fighter-up"  />
                                    <v-icon name="jira" title="jira"  />
                                    <v-icon name="joget" title="joget"  />
                                    <v-icon name="joint" title="joint"  />
                                    <v-icon name="joomla" title="joomla"  />
                                    <v-icon name="js" title="js"  />
                                    <v-icon name="jsfiddle" title="jsfiddle"  />
                                    <v-icon name="jug-detergent" title="jug-detergent"  />
                                    <v-icon name="k" title="k"  />
                                    <v-icon name="kaaba" title="kaaba"  />
                                    <v-icon name="kaggle" title="kaggle"  />
                                    <v-icon name="key" title="key"  />
                                    <v-icon name="keybase" title="keybase"  />
                                    <v-icon name="keyboard" title="keyboard"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="keycdn" title="keycdn"  />
                                    <v-icon name="khanda" title="khanda"  />
                                    <v-icon name="kickstarter" title="kickstarter"  />
                                    <v-icon name="kickstarter-k" title="kickstarter-k"  />
                                    <v-icon name="kip-sign" title="kip-sign"  />
                                    <v-icon name="kit-medical" title="kit-medical"  />
                                    <v-icon name="kitchen-set" title="kitchen-set"  />
                                    <v-icon name="kiwi-bird" title="kiwi-bird"  />
                                    <v-icon name="korvue" title="korvue"  />
                                    <v-icon name="l" title="l"  />
                                    <v-icon name="land-mine-on" title="land-mine-on"  />
                                    <v-icon name="landmark" title="landmark"  />
                                    <v-icon name="landmark-dome" title="landmark-dome"  />
                                    <v-icon name="landmark-flag" title="landmark-flag"  />
                                    <v-icon name="language" title="language"  />
                                    <v-icon name="laptop" title="laptop"  />
                                    <v-icon name="laptop-code" title="laptop-code"  />
                                    <v-icon name="laptop-file" title="laptop-file"  />
                                    <v-icon name="laptop-medical" title="laptop-medical"  />
                                    <v-icon name="laravel" title="laravel"  />
                                    <v-icon name="lari-sign" title="lari-sign"  />
                                    <v-icon name="lastfm" title="lastfm"  />
                                    <v-icon name="layer-group" title="layer-group"  />
                                    <v-icon name="leaf" title="leaf"  />
                                    <v-icon name="leanpub" title="leanpub"  />
                                    <v-icon name="left-long" title="left-long"  />
                                    <v-icon name="left-right" title="left-right"  />
                                    <v-icon name="lemon" title="lemon"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="less" title="less"  />
                                    <v-icon name="less-than" title="less-than"  />
                                    <v-icon name="less-than-equal" title="less-than-equal"  />
                                    <v-icon name="life-ring" title="life-ring"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="lightbulb" title="lightbulb"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="line" title="line"  />
                                    <v-icon name="lines-leaning" title="lines-leaning"  />
                                    <v-icon name="link" title="link"  />
                                    <v-icon name="link-slash" title="link-slash"  />
                                    <v-icon name="linkedin" title="linkedin"  />
                                    <v-icon name="linkedin-in" title="linkedin-in"  />
                                    <v-icon name="linode" title="linode"  />
                                    <v-icon name="linux" title="linux"  />
                                    <v-icon name="lira-sign" title="lira-sign"  />
                                    <v-icon name="list" title="list"  />
                                    <v-icon name="list-check" title="list-check"  />
                                    <v-icon name="list-ol" title="list-ol"  />
                                    <v-icon name="list-ul" title="list-ul"  />
                                    <v-icon name="litecoin-sign" title="litecoin-sign"  />
                                    <v-icon name="location-arrow" title="location-arrow"  />
                                    <v-icon name="location-crosshairs" title="location-crosshairs"  />
                                    <v-icon name="location-dot" title="location-dot"  />
                                    <v-icon name="location-pin" title="location-pin"  />
                                    <v-icon name="location-pin-lock" title="location-pin-lock"  />
                                    <v-icon name="lock" title="lock"  />
                                    <v-icon name="lock-open" title="lock-open"  />
                                    <v-icon name="locust" title="locust"  />
                                    <v-icon name="lungs" title="lungs"  />
                                    <v-icon name="lungs-virus" title="lungs-virus"  />
                                    <v-icon name="lyft" title="lyft"  />
                                    <v-icon name="m" title="m"  />
                                    <v-icon name="magento" title="magento"  />
                                    <v-icon name="magnet" title="magnet"  />
                                    <v-icon name="magnifying-glass" title="magnifying-glass"  />
                                    <v-icon name="magnifying-glass-arrow-right" title="magnifying-glass-arrow-right"  />
                                    <v-icon name="magnifying-glass-chart" title="magnifying-glass-chart"  />
                                    <v-icon name="magnifying-glass-dollar" title="magnifying-glass-dollar"  />
                                    <v-icon name="magnifying-glass-location" title="magnifying-glass-location"  />
                                    <v-icon name="magnifying-glass-minus" title="magnifying-glass-minus"  />
                                    <v-icon name="magnifying-glass-plus" title="magnifying-glass-plus"  />
                                    <v-icon name="mailchimp" title="mailchimp"  />
                                    <v-icon name="manat-sign" title="manat-sign"  />
                                    <v-icon name="mandalorian" title="mandalorian"  />
                                    <v-icon name="map" title="map"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="map-location" title="map-location"  />
                                    <v-icon name="map-location-dot" title="map-location-dot"  />
                                    <v-icon name="map-pin" title="map-pin"  />
                                    <v-icon name="markdown" title="markdown"  />
                                    <v-icon name="marker" title="marker"  />
                                    <v-icon name="mars" title="mars"  />
                                    <v-icon name="mars-and-venus" title="mars-and-venus"  />
                                    <v-icon name="mars-and-venus-burst" title="mars-and-venus-burst"  />
                                    <v-icon name="mars-double" title="mars-double"  />
                                    <v-icon name="mars-stroke" title="mars-stroke"  />
                                    <v-icon name="mars-stroke-right" title="mars-stroke-right"  />
                                    <v-icon name="mars-stroke-up" title="mars-stroke-up"  />
                                    <v-icon name="martini-glass" title="martini-glass"  />
                                    <v-icon name="martini-glass-citrus" title="martini-glass-citrus"  />
                                    <v-icon name="martini-glass-empty" title="martini-glass-empty"  />
                                    <v-icon name="mask" title="mask"  />
                                    <v-icon name="mask-face" title="mask-face"  />
                                    <v-icon name="mask-ventilator" title="mask-ventilator"  />
                                    <v-icon name="masks-theater" title="masks-theater"  />
                                    <v-icon name="mastodon" title="mastodon"  />
                                    <v-icon name="mattress-pillow" title="mattress-pillow"  />
                                    <v-icon name="maxcdn" title="maxcdn"  />
                                    <v-icon name="maximize" title="maximize"  />
                                    <v-icon name="mdb" title="mdb"  />
                                    <v-icon name="medal" title="medal"  />
                                    <v-icon name="medapps" title="medapps"  />
                                    <v-icon name="medium" title="medium"  />
                                    <v-icon name="medrt" title="medrt"  />
                                    <v-icon name="meetup" title="meetup"  />
                                    <v-icon name="megaport" title="megaport"  />
                                    <v-icon name="memory" title="memory"  />
                                    <v-icon name="mendeley" title="mendeley"  />
                                    <v-icon name="menorah" title="menorah"  />
                                    <v-icon name="mercury" title="mercury"  />
                                    <v-icon name="message" title="message"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="meta" title="meta"  />
                                    <v-icon name="meteor" title="meteor"  />
                                    <v-icon name="microblog" title="microblog"  />
                                    <v-icon name="microchip" title="microchip"  />
                                    <v-icon name="microphone" title="microphone"  />
                                    <v-icon name="microphone-lines" title="microphone-lines"  />
                                    <v-icon name="microphone-lines-slash" title="microphone-lines-slash"  />
                                    <v-icon name="microphone-slash" title="microphone-slash"  />
                                    <v-icon name="microscope" title="microscope"  />
                                    <v-icon name="microsoft" title="microsoft"  />
                                    <v-icon name="mill-sign" title="mill-sign"  />
                                    <v-icon name="minimize" title="minimize"  />
                                    <v-icon name="minus" title="minus"  />
                                    <v-icon name="mitten" title="mitten"  />
                                    <v-icon name="mix" title="mix"  />
                                    <v-icon name="mixcloud" title="mixcloud"  />
                                    <v-icon name="mixer" title="mixer"  />
                                    <v-icon name="mizuni" title="mizuni"  />
                                    <v-icon name="mobile" title="mobile"  />
                                    <v-icon name="mobile-button" title="mobile-button"  />
                                    <v-icon name="mobile-retro" title="mobile-retro"  />
                                    <v-icon name="mobile-screen" title="mobile-screen"  />
                                    <v-icon name="mobile-screen-button" title="mobile-screen-button"  />
                                    <v-icon name="modx" title="modx"  />
                                    <v-icon name="monero" title="monero"  />
                                    <v-icon name="money-bill" title="money-bill"  />
                                    <v-icon name="money-bill-1" title="money-bill-1"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="money-bill-1-wave" title="money-bill-1-wave"  />
                                    <v-icon name="money-bill-transfer" title="money-bill-transfer"  />
                                    <v-icon name="money-bill-trend-up" title="money-bill-trend-up"  />
                                    <v-icon name="money-bill-wave" title="money-bill-wave"  />
                                    <v-icon name="money-bill-wheat" title="money-bill-wheat"  />
                                    <v-icon name="money-bills" title="money-bills"  />
                                    <v-icon name="money-check" title="money-check"  />
                                    <v-icon name="money-check-dollar" title="money-check-dollar"  />
                                    <v-icon name="monument" title="monument"  />
                                    <v-icon name="moon" title="moon"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="mortar-pestle" title="mortar-pestle"  />
                                    <v-icon name="mosque" title="mosque"  />
                                    <v-icon name="mosquito" title="mosquito"  />
                                    <v-icon name="mosquito-net" title="mosquito-net"  />
                                    <v-icon name="motorcycle" title="motorcycle"  />
                                    <v-icon name="mound" title="mound"  />
                                    <v-icon name="mountain" title="mountain"  />
                                    <v-icon name="mountain-city" title="mountain-city"  />
                                    <v-icon name="mountain-sun" title="mountain-sun"  />
                                    <v-icon name="mug-hot" title="mug-hot"  />
                                    <v-icon name="mug-saucer" title="mug-saucer"  />
                                    <v-icon name="music" title="music"  />
                                    <v-icon name="n" title="n"  />
                                    <v-icon name="naira-sign" title="naira-sign"  />
                                    <v-icon name="napster" title="napster"  />
                                    <v-icon name="neos" title="neos"  />
                                    <v-icon name="network-wired" title="network-wired"  />
                                    <v-icon name="neuter" title="neuter"  />
                                    <v-icon name="newspaper" title="newspaper"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="nfc-directional" title="nfc-directional"  />
                                    <v-icon name="nfc-symbol" title="nfc-symbol"  />
                                    <v-icon name="nimblr" title="nimblr"  />
                                    <v-icon name="node" title="node"  />
                                    <v-icon name="node-js" title="node-js"  />
                                    <v-icon name="not-equal" title="not-equal"  />
                                    <v-icon name="note-sticky" title="note-sticky"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="notes-medical" title="notes-medical"  />
                                    <v-icon name="npm" title="npm"  />
                                    <v-icon name="ns8" title="ns8"  />
                                    <v-icon name="nutritionix" title="nutritionix"  />
                                    <v-icon name="o" title="o"  />
                                    <v-icon name="object-group" title="object-group"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="object-ungroup" title="object-ungroup"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="octopus-deploy" title="octopus-deploy"  />
                                    <v-icon name="odnoklassniki" title="odnoklassniki"  />
                                    <v-icon name="oil-can" title="oil-can"  />
                                    <v-icon name="oil-well" title="oil-well"  />
                                    <v-icon name="old-republic" title="old-republic"  />
                                    <v-icon name="om" title="om"  />
                                    <v-icon name="opencart" title="opencart"  />
                                    <v-icon name="openid" title="openid"  />
                                    <v-icon name="opera" title="opera"  />
                                    <v-icon name="optin-monster" title="optin-monster"  />
                                    <v-icon name="orcid" title="orcid"  />
                                    <v-icon name="osi" title="osi"  />
                                    <v-icon name="otter" title="otter"  />
                                    <v-icon name="outdent" title="outdent"  />
                                    <v-icon name="p" title="p"  />
                                    <v-icon name="padlet" title="padlet"  />
                                    <v-icon name="page4" title="page4"  />
                                    <v-icon name="pagelines" title="pagelines"  />
                                    <v-icon name="pager" title="pager"  />
                                    <v-icon name="paint-roller" title="paint-roller"  />
                                    <v-icon name="paintbrush" title="paintbrush"  />
                                    <v-icon name="palette" title="palette"  />
                                    <v-icon name="palfed" title="palfed"  />
                                    <v-icon name="pallet" title="pallet"  />
                                    <v-icon name="panorama" title="panorama"  />
                                    <v-icon name="paper-plane" title="paper-plane"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="paperclip" title="paperclip"  />
                                    <v-icon name="parachute-box" title="parachute-box"  />
                                    <v-icon name="paragraph" title="paragraph"  />
                                    <v-icon name="passport" title="passport"  />
                                    <v-icon name="paste" title="paste"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="patreon" title="patreon"  />
                                    <v-icon name="pause" title="pause"  />
                                    <v-icon name="paw" title="paw"  />
                                    <v-icon name="paypal" title="paypal"  />
                                    <v-icon name="peace" title="peace"  />
                                    <v-icon name="pen" title="pen"  />
                                    <v-icon name="pen-clip" title="pen-clip"  />
                                    <v-icon name="pen-fancy" title="pen-fancy"  />
                                    <v-icon name="pen-nib" title="pen-nib"  />
                                    <v-icon name="pen-ruler" title="pen-ruler"  />
                                    <v-icon name="pen-to-square" title="pen-to-square"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="pencil" title="pencil"  />
                                    <v-icon name="people-arrows" title="people-arrows"  />
                                    <v-icon name="people-carry-box" title="people-carry-box"  />
                                    <v-icon name="people-group" title="people-group"  />
                                    <v-icon name="people-line" title="people-line"  />
                                    <v-icon name="people-pulling" title="people-pulling"  />
                                    <v-icon name="people-robbery" title="people-robbery"  />
                                    <v-icon name="people-roof" title="people-roof"  />
                                    <v-icon name="pepper-hot" title="pepper-hot"  />
                                    <v-icon name="perbyte" title="perbyte"  />
                                    <v-icon name="percent" title="percent"  />
                                    <v-icon name="periscope" title="periscope"  />
                                    <v-icon name="person" title="person"  />
                                    <v-icon name="person-arrow-down-to-line" title="person-arrow-down-to-line"  />
                                    <v-icon name="person-arrow-up-from-line" title="person-arrow-up-from-line"  />
                                    <v-icon name="person-biking" title="person-biking"  />
                                    <v-icon name="person-booth" title="person-booth"  />
                                    <v-icon name="person-breastfeeding" title="person-breastfeeding"  />
                                    <v-icon name="person-burst" title="person-burst"  />
                                    <v-icon name="person-cane" title="person-cane"  />
                                    <v-icon name="person-chalkboard" title="person-chalkboard"  />
                                    <v-icon name="person-circle-check" title="person-circle-check"  />
                                    <v-icon name="person-circle-exclamation" title="person-circle-exclamation"  />
                                    <v-icon name="person-circle-minus" title="person-circle-minus"  />
                                    <v-icon name="person-circle-plus" title="person-circle-plus"  />
                                    <v-icon name="person-circle-question" title="person-circle-question"  />
                                    <v-icon name="person-circle-xmark" title="person-circle-xmark"  />
                                    <v-icon name="person-digging" title="person-digging"  />
                                    <v-icon name="person-dots-from-line" title="person-dots-from-line"  />
                                    <v-icon name="person-dress" title="person-dress"  />
                                    <v-icon name="person-dress-burst" title="person-dress-burst"  />
                                    <v-icon name="person-drowning" title="person-drowning"  />
                                    <v-icon name="person-falling" title="person-falling"  />
                                    <v-icon name="person-falling-burst" title="person-falling-burst"  />
                                    <v-icon name="person-half-dress" title="person-half-dress"  />
                                    <v-icon name="person-harassing" title="person-harassing"  />
                                    <v-icon name="person-hiking" title="person-hiking"  />
                                    <v-icon name="person-military-pointing" title="person-military-pointing"  />
                                    <v-icon name="person-military-rifle" title="person-military-rifle"  />
                                    <v-icon name="person-military-to-person" title="person-military-to-person"  />
                                    <v-icon name="person-praying" title="person-praying"  />
                                    <v-icon name="person-pregnant" title="person-pregnant"  />
                                    <v-icon name="person-rays" title="person-rays"  />
                                    <v-icon name="person-rifle" title="person-rifle"  />
                                    <v-icon name="person-running" title="person-running"  />
                                    <v-icon name="person-shelter" title="person-shelter"  />
                                    <v-icon name="person-skating" title="person-skating"  />
                                    <v-icon name="person-skiing" title="person-skiing"  />
                                    <v-icon name="person-skiing-nordic" title="person-skiing-nordic"  />
                                    <v-icon name="person-snowboarding" title="person-snowboarding"  />
                                    <v-icon name="person-swimming" title="person-swimming"  />
                                    <v-icon name="person-through-window" title="person-through-window"  />
                                    <v-icon name="person-walking" title="person-walking"  />
                                    <v-icon name="person-walking-arrow-loop-left" title="person-walking-arrow-loop-left"  />
                                    <v-icon name="person-walking-arrow-right" title="person-walking-arrow-right"  />
                                    <v-icon name="person-walking-dashed-line-arrow-right" title="person-walking-dashed-line-arrow-right"  />
                                    <v-icon name="person-walking-luggage" title="person-walking-luggage"  />
                                    <v-icon name="person-walking-with-cane" title="person-walking-with-cane"  />
                                    <v-icon name="peseta-sign" title="peseta-sign"  />
                                    <v-icon name="peso-sign" title="peso-sign"  />
                                    <v-icon name="phabricator" title="phabricator"  />
                                    <v-icon name="phoenix-framework" title="phoenix-framework"  />
                                    <v-icon name="phoenix-squadron" title="phoenix-squadron"  />
                                    <v-icon name="phone" title="phone"  />
                                    <v-icon name="phone-flip" title="phone-flip"  />
                                    <v-icon name="phone-slash" title="phone-slash"  />
                                    <v-icon name="phone-volume" title="phone-volume"  />
                                    <v-icon name="photo-film" title="photo-film"  />
                                    <v-icon name="php" title="php"  />
                                    <v-icon name="pied-piper" title="pied-piper"  />
                                    <v-icon name="pied-piper-alt" title="pied-piper-alt"  />
                                    <v-icon name="pied-piper-hat" title="pied-piper-hat"  />
                                    <v-icon name="pied-piper-pp" title="pied-piper-pp"  />
                                    <v-icon name="piggy-bank" title="piggy-bank"  />
                                    <v-icon name="pills" title="pills"  />
                                    <v-icon name="pinterest" title="pinterest"  />
                                    <v-icon name="pinterest-p" title="pinterest-p"  />
                                    <v-icon name="pix" title="pix"  />
                                    <v-icon name="pizza-slice" title="pizza-slice"  />
                                    <v-icon name="place-of-worship" title="place-of-worship"  />
                                    <v-icon name="plane" title="plane"  />
                                    <v-icon name="plane-arrival" title="plane-arrival"  />
                                    <v-icon name="plane-circle-check" title="plane-circle-check"  />
                                    <v-icon name="plane-circle-exclamation" title="plane-circle-exclamation"  />
                                    <v-icon name="plane-circle-xmark" title="plane-circle-xmark"  />
                                    <v-icon name="plane-departure" title="plane-departure"  />
                                    <v-icon name="plane-lock" title="plane-lock"  />
                                    <v-icon name="plane-slash" title="plane-slash"  />
                                    <v-icon name="plane-up" title="plane-up"  />
                                    <v-icon name="plant-wilt" title="plant-wilt"  />
                                    <v-icon name="plate-wheat" title="plate-wheat"  />
                                    <v-icon name="play" title="play"  />
                                    <v-icon name="playstation" title="playstation"  />
                                    <v-icon name="plug" title="plug"  />
                                    <v-icon name="plug-circle-bolt" title="plug-circle-bolt"  />
                                    <v-icon name="plug-circle-check" title="plug-circle-check"  />
                                    <v-icon name="plug-circle-exclamation" title="plug-circle-exclamation"  />
                                    <v-icon name="plug-circle-minus" title="plug-circle-minus"  />
                                    <v-icon name="plug-circle-plus" title="plug-circle-plus"  />
                                    <v-icon name="plug-circle-xmark" title="plug-circle-xmark"  />
                                    <v-icon name="plus" title="plus"  />
                                    <v-icon name="plus-minus" title="plus-minus"  />
                                    <v-icon name="podcast" title="podcast"  />
                                    <v-icon name="poo" title="poo"  />
                                    <v-icon name="poo-storm" title="poo-storm"  />
                                    <v-icon name="poop" title="poop"  />
                                    <v-icon name="power-off" title="power-off"  />
                                    <v-icon name="prescription" title="prescription"  />
                                    <v-icon name="prescription-bottle" title="prescription-bottle"  />
                                    <v-icon name="prescription-bottle-medical" title="prescription-bottle-medical"  />
                                    <v-icon name="print" title="print"  />
                                    <v-icon name="product-hunt" title="product-hunt"  />
                                    <v-icon name="pump-medical" title="pump-medical"  />
                                    <v-icon name="pump-soap" title="pump-soap"  />
                                    <v-icon name="pushed" title="pushed"  />
                                    <v-icon name="puzzle-piece" title="puzzle-piece"  />
                                    <v-icon name="python" title="python"  />
                                    <v-icon name="q" title="q"  />
                                    <v-icon name="qq" title="qq"  />
                                    <v-icon name="qrcode" title="qrcode"  />
                                    <v-icon name="question" title="question"  />
                                    <v-icon name="quinscape" title="quinscape"  />
                                    <v-icon name="quora" title="quora"  />
                                    <v-icon name="quote-left" title="quote-left"  />
                                    <v-icon name="quote-right" title="quote-right"  />
                                    <v-icon name="r" title="r"  />
                                    <v-icon name="r-project" title="r-project"  />
                                    <v-icon name="radiation" title="radiation"  />
                                    <v-icon name="radio" title="radio"  />
                                    <v-icon name="rainbow" title="rainbow"  />
                                    <v-icon name="ranking-star" title="ranking-star"  />
                                    <v-icon name="raspberry-pi" title="raspberry-pi"  />
                                    <v-icon name="ravelry" title="ravelry"  />
                                    <v-icon name="react" title="react"  />
                                    <v-icon name="reacteurope" title="reacteurope"  />
                                    <v-icon name="readme" title="readme"  />
                                    <v-icon name="rebel" title="rebel"  />
                                    <v-icon name="receipt" title="receipt"  />
                                    <v-icon name="record-vinyl" title="record-vinyl"  />
                                    <v-icon name="rectangle-ad" title="rectangle-ad"  />
                                    <v-icon name="rectangle-list" title="rectangle-list"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="rectangle-xmark" title="rectangle-xmark"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="recycle" title="recycle"  />
                                    <v-icon name="red-river" title="red-river"  />
                                    <v-icon name="reddit" title="reddit"  />
                                    <v-icon name="reddit-alien" title="reddit-alien"  />
                                    <v-icon name="redhat" title="redhat"  />
                                    <v-icon name="registered" title="registered"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="renren" title="renren"  />
                                    <v-icon name="repeat" title="repeat"  />
                                    <v-icon name="reply" title="reply"  />
                                    <v-icon name="reply-all" title="reply-all"  />
                                    <v-icon name="replyd" title="replyd"  />
                                    <v-icon name="republican" title="republican"  />
                                    <v-icon name="researchgate" title="researchgate"  />
                                    <v-icon name="resolving" title="resolving"  />
                                    <v-icon name="restroom" title="restroom"  />
                                    <v-icon name="retweet" title="retweet"  />
                                    <v-icon name="rev" title="rev"  />
                                    <v-icon name="ribbon" title="ribbon"  />
                                    <v-icon name="right-from-bracket" title="right-from-bracket"  />
                                    <v-icon name="right-left" title="right-left"  />
                                    <v-icon name="right-long" title="right-long"  />
                                    <v-icon name="right-to-bracket" title="right-to-bracket"  />
                                    <v-icon name="ring" title="ring"  />
                                    <v-icon name="road" title="road"  />
                                    <v-icon name="road-barrier" title="road-barrier"  />
                                    <v-icon name="road-bridge" title="road-bridge"  />
                                    <v-icon name="road-circle-check" title="road-circle-check"  />
                                    <v-icon name="road-circle-exclamation" title="road-circle-exclamation"  />
                                    <v-icon name="road-circle-xmark" title="road-circle-xmark"  />
                                    <v-icon name="road-lock" title="road-lock"  />
                                    <v-icon name="road-spikes" title="road-spikes"  />
                                    <v-icon name="robot" title="robot"  />
                                    <v-icon name="rocket" title="rocket"  />
                                    <v-icon name="rocketchat" title="rocketchat"  />
                                    <v-icon name="rockrms" title="rockrms"  />
                                    <v-icon name="rotate" title="rotate"  />
                                    <v-icon name="rotate-left" title="rotate-left"  />
                                    <v-icon name="rotate-right" title="rotate-right"  />
                                    <v-icon name="route" title="route"  />
                                    <v-icon name="rss" title="rss"  />
                                    <v-icon name="ruble-sign" title="ruble-sign"  />
                                    <v-icon name="rug" title="rug"  />
                                    <v-icon name="ruler" title="ruler"  />
                                    <v-icon name="ruler-combined" title="ruler-combined"  />
                                    <v-icon name="ruler-horizontal" title="ruler-horizontal"  />
                                    <v-icon name="ruler-vertical" title="ruler-vertical"  />
                                    <v-icon name="rupee-sign" title="rupee-sign"  />
                                    <v-icon name="rupiah-sign" title="rupiah-sign"  />
                                    <v-icon name="rust" title="rust"  />
                                    <v-icon name="s" title="s"  />
                                    <v-icon name="sack-dollar" title="sack-dollar"  />
                                    <v-icon name="sack-xmark" title="sack-xmark"  />
                                    <v-icon name="safari" title="safari"  />
                                    <v-icon name="sailboat" title="sailboat"  />
                                    <v-icon name="salesforce" title="salesforce"  />
                                    <v-icon name="sass" title="sass"  />
                                    <v-icon name="satellite" title="satellite"  />
                                    <v-icon name="satellite-dish" title="satellite-dish"  />
                                    <v-icon name="scale-balanced" title="scale-balanced"  />
                                    <v-icon name="scale-unbalanced" title="scale-unbalanced"  />
                                    <v-icon name="scale-unbalanced-flip" title="scale-unbalanced-flip"  />
                                    <v-icon name="schlix" title="schlix"  />
                                    <v-icon name="school" title="school"  />
                                    <v-icon name="school-circle-check" title="school-circle-check"  />
                                    <v-icon name="school-circle-exclamation" title="school-circle-exclamation"  />
                                    <v-icon name="school-circle-xmark" title="school-circle-xmark"  />
                                    <v-icon name="school-flag" title="school-flag"  />
                                    <v-icon name="school-lock" title="school-lock"  />
                                    <v-icon name="scissors" title="scissors"  />
                                    <v-icon name="screenpal" title="screenpal"  />
                                    <v-icon name="screwdriver" title="screwdriver"  />
                                    <v-icon name="screwdriver-wrench" title="screwdriver-wrench"  />
                                    <v-icon name="scribd" title="scribd"  />
                                    <v-icon name="scroll" title="scroll"  />
                                    <v-icon name="scroll-torah" title="scroll-torah"  />
                                    <v-icon name="sd-card" title="sd-card"  />
                                    <v-icon name="searchengin" title="searchengin"  />
                                    <v-icon name="section" title="section"  />
                                    <v-icon name="seedling" title="seedling"  />
                                    <v-icon name="sellcast" title="sellcast"  />
                                    <v-icon name="sellsy" title="sellsy"  />
                                    <v-icon name="server" title="server"  />
                                    <v-icon name="servicestack" title="servicestack"  />
                                    <v-icon name="shapes" title="shapes"  />
                                    <v-icon name="share" title="share"  />
                                    <v-icon name="share-from-square" title="share-from-square"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="share-nodes" title="share-nodes"  />
                                    <v-icon name="sheet-plastic" title="sheet-plastic"  />
                                    <v-icon name="shekel-sign" title="shekel-sign"  />
                                    <v-icon name="shield" title="shield"  />
                                    <v-icon name="shield-cat" title="shield-cat"  />
                                    <v-icon name="shield-dog" title="shield-dog"  />
                                    <v-icon name="shield-halved" title="shield-halved"  />
                                    <v-icon name="shield-heart" title="shield-heart"  />
                                    <v-icon name="shield-virus" title="shield-virus"  />
                                    <v-icon name="ship" title="ship"  />
                                    <v-icon name="shirt" title="shirt"  />
                                    <v-icon name="shirtsinbulk" title="shirtsinbulk"  />
                                    <v-icon name="shoe-prints" title="shoe-prints"  />
                                    <v-icon name="shop" title="shop"  />
                                    <v-icon name="shop-lock" title="shop-lock"  />
                                    <v-icon name="shop-slash" title="shop-slash"  />
                                    <v-icon name="shopify" title="shopify"  />
                                    <v-icon name="shopware" title="shopware"  />
                                    <v-icon name="shower" title="shower"  />
                                    <v-icon name="shrimp" title="shrimp"  />
                                    <v-icon name="shuffle" title="shuffle"  />
                                    <v-icon name="shuttle-space" title="shuttle-space"  />
                                    <v-icon name="sign-hanging" title="sign-hanging"  />
                                    <v-icon name="signal" title="signal"  />
                                    <v-icon name="signature" title="signature"  />
                                    <v-icon name="signs-post" title="signs-post"  />
                                    <v-icon name="sim-card" title="sim-card"  />
                                    <v-icon name="simplybuilt" title="simplybuilt"  />
                                    <v-icon name="sink" title="sink"  />
                                    <v-icon name="sistrix" title="sistrix"  />
                                    <v-icon name="sitemap" title="sitemap"  />
                                    <v-icon name="sith" title="sith"  />
                                    <v-icon name="sitrox" title="sitrox"  />
                                    <v-icon name="sketch" title="sketch"  />
                                    <v-icon name="skull" title="skull"  />
                                    <v-icon name="skull-crossbones" title="skull-crossbones"  />
                                    <v-icon name="skyatlas" title="skyatlas"  />
                                    <v-icon name="skype" title="skype"  />
                                    <v-icon name="slack" title="slack"  />
                                    <v-icon name="slash" title="slash"  />
                                    <v-icon name="sleigh" title="sleigh"  />
                                    <v-icon name="sliders" title="sliders"  />
                                    <v-icon name="slideshare" title="slideshare"  />
                                    <v-icon name="smog" title="smog"  />
                                    <v-icon name="smoking" title="smoking"  />
                                    <v-icon name="snapchat" title="snapchat"  />
                                    <v-icon name="snowflake" title="snowflake"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="snowman" title="snowman"  />
                                    <v-icon name="snowplow" title="snowplow"  />
                                    <v-icon name="soap" title="soap"  />
                                    <v-icon name="socks" title="socks"  />
                                    <v-icon name="solar-panel" title="solar-panel"  />
                                    <v-icon name="sort" title="sort"  />
                                    <v-icon name="sort-down" title="sort-down"  />
                                    <v-icon name="sort-up" title="sort-up"  />
                                    <v-icon name="soundcloud" title="soundcloud"  />
                                    <v-icon name="sourcetree" title="sourcetree"  />
                                    <v-icon name="spa" title="spa"  />
                                    <v-icon name="space-awesome" title="space-awesome"  />
                                    <v-icon name="spaghetti-monster-flying" title="spaghetti-monster-flying"  />
                                    <v-icon name="speakap" title="speakap"  />
                                    <v-icon name="speaker-deck" title="speaker-deck"  />
                                    <v-icon name="spell-check" title="spell-check"  />
                                    <v-icon name="spider" title="spider"  />
                                    <v-icon name="spinner" title="spinner"  />
                                    <v-icon name="splotch" title="splotch"  />
                                    <v-icon name="spoon" title="spoon"  />
                                    <v-icon name="spotify" title="spotify"  />
                                    <v-icon name="spray-can" title="spray-can"  />
                                    <v-icon name="spray-can-sparkles" title="spray-can-sparkles"  />
                                    <v-icon name="square" title="square"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-arrow-up-right" title="square-arrow-up-right"  />
                                    <v-icon name="square-behance" title="square-behance"  />
                                    <v-icon name="square-caret-down" title="square-caret-down"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-caret-left" title="square-caret-left"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-caret-right" title="square-caret-right"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-caret-up" title="square-caret-up"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-check" title="square-check"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-dribbble" title="square-dribbble"  />
                                    <v-icon name="square-envelope" title="square-envelope"  />
                                    <v-icon name="square-facebook" title="square-facebook"  />
                                    <v-icon name="square-font-awesome" title="square-font-awesome"  />
                                    <v-icon name="square-font-awesome-stroke" title="square-font-awesome-stroke"  />
                                    <v-icon name="square-full" title="square-full"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-git" title="square-git"  />
                                    <v-icon name="square-github" title="square-github"  />
                                    <v-icon name="square-gitlab" title="square-gitlab"  />
                                    <v-icon name="square-google-plus" title="square-google-plus"  />
                                    <v-icon name="square-h" title="square-h"  />
                                    <v-icon name="square-hacker-news" title="square-hacker-news"  />
                                    <v-icon name="square-instagram" title="square-instagram"  />
                                    <v-icon name="square-js" title="square-js"  />
                                    <v-icon name="square-lastfm" title="square-lastfm"  />
                                    <v-icon name="square-minus" title="square-minus"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-nfi" title="square-nfi"  />
                                    <v-icon name="square-odnoklassniki" title="square-odnoklassniki"  />
                                    <v-icon name="square-parking" title="square-parking"  />
                                    <v-icon name="square-pen" title="square-pen"  />
                                    <v-icon name="square-person-confined" title="square-person-confined"  />
                                    <v-icon name="square-phone" title="square-phone"  />
                                    <v-icon name="square-phone-flip" title="square-phone-flip"  />
                                    <v-icon name="square-pied-piper" title="square-pied-piper"  />
                                    <v-icon name="square-pinterest" title="square-pinterest"  />
                                    <v-icon name="square-plus" title="square-plus"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="square-poll-horizontal" title="square-poll-horizontal"  />
                                    <v-icon name="square-poll-vertical" title="square-poll-vertical"  />
                                    <v-icon name="square-reddit" title="square-reddit"  />
                                    <v-icon name="square-root-variable" title="square-root-variable"  />
                                    <v-icon name="square-rss" title="square-rss"  />
                                    <v-icon name="square-share-nodes" title="square-share-nodes"  />
                                    <v-icon name="square-snapchat" title="square-snapchat"  />
                                    <v-icon name="square-steam" title="square-steam"  />
                                    <v-icon name="square-tumblr" title="square-tumblr"  />
                                    <v-icon name="square-twitter" title="square-twitter"  />
                                    <v-icon name="square-up-right" title="square-up-right"  />
                                    <v-icon name="square-viadeo" title="square-viadeo"  />
                                    <v-icon name="square-vimeo" title="square-vimeo"  />
                                    <v-icon name="square-virus" title="square-virus"  />
                                    <v-icon name="square-whatsapp" title="square-whatsapp"  />
                                    <v-icon name="square-xing" title="square-xing"  />
                                    <v-icon name="square-xmark" title="square-xmark"  />
                                    <v-icon name="square-youtube" title="square-youtube"  />
                                    <v-icon name="squarespace" title="squarespace"  />
                                    <v-icon name="stack-exchange" title="stack-exchange"  />
                                    <v-icon name="stack-overflow" title="stack-overflow"  />
                                    <v-icon name="stackpath" title="stackpath"  />
                                    <v-icon name="staff-snake" title="staff-snake"  />
                                    <v-icon name="stairs" title="stairs"  />
                                    <v-icon name="stamp" title="stamp"  />
                                    <v-icon name="stapler" title="stapler"  />
                                    <v-icon name="star" title="star"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="star-and-crescent" title="star-and-crescent"  />
                                    <v-icon name="star-half" title="star-half"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="star-half-stroke" title="star-half-stroke"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="star-of-david" title="star-of-david"  />
                                    <v-icon name="star-of-life" title="star-of-life"  />
                                    <v-icon name="staylinked" title="staylinked"  />
                                    <v-icon name="steam" title="steam"  />
                                    <v-icon name="steam-symbol" title="steam-symbol"  />
                                    <v-icon name="sterling-sign" title="sterling-sign"  />
                                    <v-icon name="stethoscope" title="stethoscope"  />
                                    <v-icon name="sticker-mule" title="sticker-mule"  />
                                    <v-icon name="stop" title="stop"  />
                                    <v-icon name="stopwatch" title="stopwatch"  />
                                    <v-icon name="stopwatch-20" title="stopwatch-20"  />
                                    <v-icon name="store" title="store"  />
                                    <v-icon name="store-slash" title="store-slash"  />
                                    <v-icon name="strava" title="strava"  />
                                    <v-icon name="street-view" title="street-view"  />
                                    <v-icon name="strikethrough" title="strikethrough"  />
                                    <v-icon name="stripe" title="stripe"  />
                                    <v-icon name="stripe-s" title="stripe-s"  />
                                    <v-icon name="stroopwafel" title="stroopwafel"  />
                                    <v-icon name="studiovinari" title="studiovinari"  />
                                    <v-icon name="stumbleupon" title="stumbleupon"  />
                                    <v-icon name="stumbleupon-circle" title="stumbleupon-circle"  />
                                    <v-icon name="subscript" title="subscript"  />
                                    <v-icon name="suitcase" title="suitcase"  />
                                    <v-icon name="suitcase-medical" title="suitcase-medical"  />
                                    <v-icon name="suitcase-rolling" title="suitcase-rolling"  />
                                    <v-icon name="sun" title="sun"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="sun-plant-wilt" title="sun-plant-wilt"  />
                                    <v-icon name="superpowers" title="superpowers"  />
                                    <v-icon name="superscript" title="superscript"  />
                                    <v-icon name="supple" title="supple"  />
                                    <v-icon name="suse" title="suse"  />
                                    <v-icon name="swatchbook" title="swatchbook"  />
                                    <v-icon name="swift" title="swift"  />
                                    <v-icon name="symfony" title="symfony"  />
                                    <v-icon name="synagogue" title="synagogue"  />
                                    <v-icon name="syringe" title="syringe"  />
                                    <v-icon name="t" title="t"  />
                                    <v-icon name="table" title="table"  />
                                    <v-icon name="table-cells" title="table-cells"  />
                                    <v-icon name="table-cells-large" title="table-cells-large"  />
                                    <v-icon name="table-columns" title="table-columns"  />
                                    <v-icon name="table-list" title="table-list"  />
                                    <v-icon name="table-tennis-paddle-ball" title="table-tennis-paddle-ball"  />
                                    <v-icon name="tablet" title="tablet"  />
                                    <v-icon name="tablet-button" title="tablet-button"  />
                                    <v-icon name="tablet-screen-button" title="tablet-screen-button"  />
                                    <v-icon name="tablets" title="tablets"  />
                                    <v-icon name="tachograph-digital" title="tachograph-digital"  />
                                    <v-icon name="tag" title="tag"  />
                                    <v-icon name="tags" title="tags"  />
                                    <v-icon name="tape" title="tape"  />
                                    <v-icon name="tarp" title="tarp"  />
                                    <v-icon name="tarp-droplet" title="tarp-droplet"  />
                                    <v-icon name="taxi" title="taxi"  />
                                    <v-icon name="teamspeak" title="teamspeak"  />
                                    <v-icon name="teeth" title="teeth"  />
                                    <v-icon name="teeth-open" title="teeth-open"  />
                                    <v-icon name="telegram" title="telegram"  />
                                    <v-icon name="temperature-arrow-down" title="temperature-arrow-down"  />
                                    <v-icon name="temperature-arrow-up" title="temperature-arrow-up"  />
                                    <v-icon name="temperature-empty" title="temperature-empty"  />
                                    <v-icon name="temperature-full" title="temperature-full"  />
                                    <v-icon name="temperature-half" title="temperature-half"  />
                                    <v-icon name="temperature-high" title="temperature-high"  />
                                    <v-icon name="temperature-low" title="temperature-low"  />
                                    <v-icon name="temperature-quarter" title="temperature-quarter"  />
                                    <v-icon name="temperature-three-quarters" title="temperature-three-quarters"  />
                                    <v-icon name="tencent-weibo" title="tencent-weibo"  />
                                    <v-icon name="tenge-sign" title="tenge-sign"  />
                                    <v-icon name="tent" title="tent"  />
                                    <v-icon name="tent-arrow-down-to-line" title="tent-arrow-down-to-line"  />
                                    <v-icon name="tent-arrow-left-right" title="tent-arrow-left-right"  />
                                    <v-icon name="tent-arrow-turn-left" title="tent-arrow-turn-left"  />
                                    <v-icon name="tent-arrows-down" title="tent-arrows-down"  />
                                    <v-icon name="tents" title="tents"  />
                                    <v-icon name="terminal" title="terminal"  />
                                    <v-icon name="text-height" title="text-height"  />
                                    <v-icon name="text-slash" title="text-slash"  />
                                    <v-icon name="text-width" title="text-width"  />
                                    <v-icon name="the-red-yeti" title="the-red-yeti"  />
                                    <v-icon name="themeco" title="themeco"  />
                                    <v-icon name="themeisle" title="themeisle"  />
                                    <v-icon name="thermometer" title="thermometer"  />
                                    <v-icon name="think-peaks" title="think-peaks"  />
                                    <v-icon name="thumbs-down" title="thumbs-down"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="thumbs-up" title="thumbs-up"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="thumbtack" title="thumbtack"  />
                                    <v-icon name="ticket" title="ticket"  />
                                    <v-icon name="ticket-simple" title="ticket-simple"  />
                                    <v-icon name="tiktok" title="tiktok"  />
                                    <v-icon name="timeline" title="timeline"  />
                                    <v-icon name="toggle-off" title="toggle-off"  />
                                    <v-icon name="toggle-on" title="toggle-on"  />
                                    <v-icon name="toilet" title="toilet"  />
                                    <v-icon name="toilet-paper" title="toilet-paper"  />
                                    <v-icon name="toilet-paper-slash" title="toilet-paper-slash"  />
                                    <v-icon name="toilet-portable" title="toilet-portable"  />
                                    <v-icon name="toilets-portable" title="toilets-portable"  />
                                    <v-icon name="toolbox" title="toolbox"  />
                                    <v-icon name="tooth" title="tooth"  />
                                    <v-icon name="torii-gate" title="torii-gate"  />
                                    <v-icon name="tornado" title="tornado"  />
                                    <v-icon name="tower-broadcast" title="tower-broadcast"  />
                                    <v-icon name="tower-cell" title="tower-cell"  />
                                    <v-icon name="tower-observation" title="tower-observation"  />
                                    <v-icon name="tractor" title="tractor"  />
                                    <v-icon name="trade-federation" title="trade-federation"  />
                                    <v-icon name="trademark" title="trademark"  />
                                    <v-icon name="traffic-light" title="traffic-light"  />
                                    <v-icon name="trailer" title="trailer"  />
                                    <v-icon name="train" title="train"  />
                                    <v-icon name="train-subway" title="train-subway"  />
                                    <v-icon name="train-tram" title="train-tram"  />
                                    <v-icon name="transgender" title="transgender"  />
                                    <v-icon name="trash" title="trash"  />
                                    <v-icon name="trash-arrow-up" title="trash-arrow-up"  />
                                    <v-icon name="trash-can" title="trash-can"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="trash-can-arrow-up" title="trash-can-arrow-up"  />
                                    <v-icon name="tree" title="tree"  />
                                    <v-icon name="tree-city" title="tree-city"  />
                                    <v-icon name="trello" title="trello"  />
                                    <v-icon name="triangle-exclamation" title="triangle-exclamation"  />
                                    <v-icon name="trophy" title="trophy"  />
                                    <v-icon name="trowel" title="trowel"  />
                                    <v-icon name="trowel-bricks" title="trowel-bricks"  />
                                    <v-icon name="truck" title="truck"  />
                                    <v-icon name="truck-arrow-right" title="truck-arrow-right"  />
                                    <v-icon name="truck-droplet" title="truck-droplet"  />
                                    <v-icon name="truck-fast" title="truck-fast"  />
                                    <v-icon name="truck-field" title="truck-field"  />
                                    <v-icon name="truck-field-un" title="truck-field-un"  />
                                    <v-icon name="truck-front" title="truck-front"  />
                                    <v-icon name="truck-medical" title="truck-medical"  />
                                    <v-icon name="truck-monster" title="truck-monster"  />
                                    <v-icon name="truck-moving" title="truck-moving"  />
                                    <v-icon name="truck-pickup" title="truck-pickup"  />
                                    <v-icon name="truck-plane" title="truck-plane"  />
                                    <v-icon name="truck-ramp-box" title="truck-ramp-box"  />
                                    <v-icon name="tty" title="tty"  />
                                    <v-icon name="tumblr" title="tumblr"  />
                                    <v-icon name="turkish-lira-sign" title="turkish-lira-sign"  />
                                    <v-icon name="turn-down" title="turn-down"  />
                                    <v-icon name="turn-up" title="turn-up"  />
                                    <v-icon name="tv" title="tv"  />
                                    <v-icon name="twitch" title="twitch"  />
                                    <v-icon name="twitter" title="twitter"  />
                                    <v-icon name="typo3" title="typo3"  />
                                    <v-icon name="u" title="u"  />
                                    <v-icon name="uber" title="uber"  />
                                    <v-icon name="ubuntu" title="ubuntu"  />
                                    <v-icon name="uikit" title="uikit"  />
                                    <v-icon name="umbraco" title="umbraco"  />
                                    <v-icon name="umbrella" title="umbrella"  />
                                    <v-icon name="umbrella-beach" title="umbrella-beach"  />
                                    <v-icon name="uncharted" title="uncharted"  />
                                    <v-icon name="underline" title="underline"  />
                                    <v-icon name="uniregistry" title="uniregistry"  />
                                    <v-icon name="unity" title="unity"  />
                                    <v-icon name="universal-access" title="universal-access"  />
                                    <v-icon name="unlock" title="unlock"  />
                                    <v-icon name="unlock-keyhole" title="unlock-keyhole"  />
                                    <v-icon name="unsplash" title="unsplash"  />
                                    <v-icon name="untappd" title="untappd"  />
                                    <v-icon name="up-down" title="up-down"  />
                                    <v-icon name="up-down-left-right" title="up-down-left-right"  />
                                    <v-icon name="up-long" title="up-long"  />
                                    <v-icon name="up-right-and-down-left-from-center" title="up-right-and-down-left-from-center"  />
                                    <v-icon name="up-right-from-square" title="up-right-from-square"  />
                                    <v-icon name="upload" title="upload"  />
                                    <v-icon name="ups" title="ups"  />
                                    <v-icon name="usb" title="usb"  />
                                    <v-icon name="user" title="user"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="user-astronaut" title="user-astronaut"  />
                                    <v-icon name="user-check" title="user-check"  />
                                    <v-icon name="user-clock" title="user-clock"  />
                                    <v-icon name="user-doctor" title="user-doctor"  />
                                    <v-icon name="user-gear" title="user-gear"  />
                                    <v-icon name="user-graduate" title="user-graduate"  />
                                    <v-icon name="user-group" title="user-group"  />
                                    <v-icon name="user-injured" title="user-injured"  />
                                    <v-icon name="user-large" title="user-large"  />
                                    <v-icon name="user-large-slash" title="user-large-slash"  />
                                    <v-icon name="user-lock" title="user-lock"  />
                                    <v-icon name="user-minus" title="user-minus"  />
                                    <v-icon name="user-ninja" title="user-ninja"  />
                                    <v-icon name="user-nurse" title="user-nurse"  />
                                    <v-icon name="user-pen" title="user-pen"  />
                                    <v-icon name="user-plus" title="user-plus"  />
                                    <v-icon name="user-secret" title="user-secret"  />
                                    <v-icon name="user-shield" title="user-shield"  />
                                    <v-icon name="user-slash" title="user-slash"  />
                                    <v-icon name="user-tag" title="user-tag"  />
                                    <v-icon name="user-tie" title="user-tie"  />
                                    <v-icon name="user-xmark" title="user-xmark"  />
                                    <v-icon name="users" title="users"  />
                                    <v-icon name="users-between-lines" title="users-between-lines"  />
                                    <v-icon name="users-gear" title="users-gear"  />
                                    <v-icon name="users-line" title="users-line"  />
                                    <v-icon name="users-rays" title="users-rays"  />
                                    <v-icon name="users-rectangle" title="users-rectangle"  />
                                    <v-icon name="users-slash" title="users-slash"  />
                                    <v-icon name="users-viewfinder" title="users-viewfinder"  />
                                    <v-icon name="usps" title="usps"  />
                                    <v-icon name="ussunnah" title="ussunnah"  />
                                    <v-icon name="utensils" title="utensils"  />
                                    <v-icon name="v" title="v"  />
                                    <v-icon name="vaadin" title="vaadin"  />
                                    <v-icon name="van-shuttle" title="van-shuttle"  />
                                    <v-icon name="vault" title="vault"  />
                                    <v-icon name="vector-square" title="vector-square"  />
                                    <v-icon name="venus" title="venus"  />
                                    <v-icon name="venus-double" title="venus-double"  />
                                    <v-icon name="venus-mars" title="venus-mars"  />
                                    <v-icon name="vest" title="vest"  />
                                    <v-icon name="vest-patches" title="vest-patches"  />
                                    <v-icon name="viacoin" title="viacoin"  />
                                    <v-icon name="viadeo" title="viadeo"  />
                                    <v-icon name="vial" title="vial"  />
                                    <v-icon name="vial-circle-check" title="vial-circle-check"  />
                                    <v-icon name="vial-virus" title="vial-virus"  />
                                    <v-icon name="vials" title="vials"  />
                                    <v-icon name="viber" title="viber"  />
                                    <v-icon name="video" title="video"  />
                                    <v-icon name="video-slash" title="video-slash"  />
                                    <v-icon name="vihara" title="vihara"  />
                                    <v-icon name="vimeo" title="vimeo"  />
                                    <v-icon name="vimeo-v" title="vimeo-v"  />
                                    <v-icon name="vine" title="vine"  />
                                    <v-icon name="virus" title="virus"  />
                                    <v-icon name="virus-covid" title="virus-covid"  />
                                    <v-icon name="virus-covid-slash" title="virus-covid-slash"  />
                                    <v-icon name="virus-slash" title="virus-slash"  />
                                    <v-icon name="viruses" title="viruses"  />
                                    <v-icon name="vk" title="vk"  />
                                    <v-icon name="vnv" title="vnv"  />
                                    <v-icon name="voicemail" title="voicemail"  />
                                    <v-icon name="volcano" title="volcano"  />
                                    <v-icon name="volleyball" title="volleyball"  />
                                    <v-icon name="volume-high" title="volume-high"  />
                                    <v-icon name="volume-low" title="volume-low"  />
                                    <v-icon name="volume-off" title="volume-off"  />
                                    <v-icon name="volume-xmark" title="volume-xmark"  />
                                    <v-icon name="vr-cardboard" title="vr-cardboard"  />
                                    <v-icon name="vuejs" title="vuejs"  />
                                    <v-icon name="w" title="w"  />
                                    <v-icon name="walkie-talkie" title="walkie-talkie"  />
                                    <v-icon name="wallet" title="wallet"  />
                                    <v-icon name="wand-magic" title="wand-magic"  />
                                    <v-icon name="wand-magic-sparkles" title="wand-magic-sparkles"  />
                                    <v-icon name="wand-sparkles" title="wand-sparkles"  />
                                    <v-icon name="warehouse" title="warehouse"  />
                                    <v-icon name="watchman-monitoring" title="watchman-monitoring"  />
                                    <v-icon name="water" title="water"  />
                                    <v-icon name="water-ladder" title="water-ladder"  />
                                    <v-icon name="wave-square" title="wave-square"  />
                                    <v-icon name="waze" title="waze"  />
                                    <v-icon name="weebly" title="weebly"  />
                                    <v-icon name="weibo" title="weibo"  />
                                    <v-icon name="weight-hanging" title="weight-hanging"  />
                                    <v-icon name="weight-scale" title="weight-scale"  />
                                    <v-icon name="weixin" title="weixin"  />
                                    <v-icon name="whatsapp" title="whatsapp"  />
                                    <v-icon name="wheat-awn" title="wheat-awn"  />
                                    <v-icon name="wheat-awn-circle-exclamation" title="wheat-awn-circle-exclamation"  />
                                    <v-icon name="wheelchair" title="wheelchair"  />
                                    <v-icon name="wheelchair-move" title="wheelchair-move"  />
                                    <v-icon name="whiskey-glass" title="whiskey-glass"  />
                                    <v-icon name="whmcs" title="whmcs"  />
                                    <v-icon name="wifi" title="wifi"  />
                                    <v-icon name="wikipedia-w" title="wikipedia-w"  />
                                    <v-icon name="wind" title="wind"  />
                                    <v-icon name="window-maximize" title="window-maximize"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="window-minimize" title="window-minimize"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="window-restore" title="window-restore"  />
                                    <v-icon name="regular" title="regular"  />
                                    <v-icon name="windows" title="windows"  />
                                    <v-icon name="wine-bottle" title="wine-bottle"  />
                                    <v-icon name="wine-glass" title="wine-glass"  />
                                    <v-icon name="wine-glass-empty" title="wine-glass-empty"  />
                                    <v-icon name="wirsindhandwerk" title="wirsindhandwerk"  />
                                    <v-icon name="wix" title="wix"  />
                                    <v-icon name="wizards-of-the-coast" title="wizards-of-the-coast"  />
                                    <v-icon name="wodu" title="wodu"  />
                                    <v-icon name="wolf-pack-battalion" title="wolf-pack-battalion"  />
                                    <v-icon name="won-sign" title="won-sign"  />
                                    <v-icon name="wordpress" title="wordpress"  />
                                    <v-icon name="wordpress-simple" title="wordpress-simple"  />
                                    <v-icon name="worm" title="worm"  />
                                    <v-icon name="wpbeginner" title="wpbeginner"  />
                                    <v-icon name="wpexplorer" title="wpexplorer"  />
                                    <v-icon name="wpforms" title="wpforms"  />
                                    <v-icon name="wpressr" title="wpressr"  />
                                    <v-icon name="wrench" title="wrench"  />
                                    <v-icon name="x" title="x"  />
                                    <v-icon name="x-ray" title="x-ray"  />
                                    <v-icon name="xbox" title="xbox"  />
                                    <v-icon name="xing" title="xing"  />
                                    <v-icon name="xmark" title="xmark"  />
                                    <v-icon name="xmarks-lines" title="xmarks-lines"  />
                                    <v-icon name="y" title="y"  />
                                    <v-icon name="y-combinator" title="y-combinator"  />
                                    <v-icon name="yahoo" title="yahoo"  />
                                    <v-icon name="yammer" title="yammer"  />
                                    <v-icon name="yandex" title="yandex"  />
                                    <v-icon name="yandex-international" title="yandex-international"  />
                                    <v-icon name="yarn" title="yarn"  />
                                    <v-icon name="yelp" title="yelp"  />
                                    <v-icon name="yen-sign" title="yen-sign"  />
                                    <v-icon name="yin-yang" title="yin-yang"  />
                                    <v-icon name="yoast" title="yoast"  />
                                    <v-icon name="youtube" title="youtube"  />
                                    <v-icon name="z" title="z"  />
                                    <v-icon name="zhihu" title="zhihu"  />
                                    <br />
                                    <br />
                                    <template>
                                        <div>
                                          <b-button-toolbar>
                                            <b-button-group class="mr-1">
                                              <b-button title="Save file">
                                                <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                                              </b-button>
                                              <b-button title="Load file">
                                                <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
                                              </b-button>
                                              <b-button title="New document">
                                                <b-icon icon="file-earmark" aria-hidden="true"></b-icon>
                                              </b-button>
                                            </b-button-group>
                                            <b-button-group class="mr-1">
                                              <b-button title="Align left">
                                                <b-icon icon="text-left" aria-hidden="true"></b-icon>
                                              </b-button>
                                              <b-button title="Align center">
                                                <b-icon icon="text-center" aria-hidden="true"></b-icon>
                                              </b-button>
                                              <b-button title="Align right">
                                                <b-icon icon="text-right" aria-hidden="true"></b-icon>
                                              </b-button>
                                            </b-button-group>
                                            <b-button-group>
                                              <b-button title="Bold">
                                                <b-icon icon="type-bold" aria-hidden="true"></b-icon>
                                              </b-button>
                                              <b-button title="Italic">
                                                <b-icon icon="type-italic" aria-hidden="true"></b-icon>
                                              </b-button>
                                              <b-button title="Underline">
                                                <b-icon icon="type-underline" aria-hidden="true"></b-icon>
                                              </b-button>
                                              <b-button title="Strikethrough">
                                                <b-icon icon="type-strikethrough" aria-hidden="true"></b-icon>
                                              </b-button>
                                            </b-button-group>
                                          </b-button-toolbar>
                                        </div>
                                      </template>
                                      <br />
                                      <template>
                                        <div style="font-size: 4rem;">
                                          <b-icon icon="bell-fill" class="border rounded p-2"></b-icon>
                                          <b-icon icon="bell-fill" class="border border-info rounded p-2" variant="info"></b-icon>
                                          <b-icon icon="bell-fill" class="rounded-circle bg-danger p-2" variant="light"></b-icon>
                                          <b-icon icon="unlock-fill" class="rounded bg-primary p-1" variant="light"></b-icon>
                                        </div>
                                      </template>
                                </div>
                            </template>
                        </div>
                    </b-sidebar>
                    </div>
                </template>     
            </div>
        </div>
    </div>
</template>
<script>
import callForBidsService from '../../../../services/CallForBidsService';
import userService from '../../../../services/UserService';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'dashboard',
    data() {
        return {
            doneLoading: false,
            auctions: [],
            callForBids: [],
        }
    },
    created() {
        this.$store.commit('loading/setLoading', true);

        callForBidsService.fetchActiveAuctions()
            .then(auctionsResponse => {
                this.auctions = auctionsResponse.data;

                return callForBidsService.fetchActiveCallForBids()
                    .then(callForBidsResponse => {
                        this.callForBids = callForBidsResponse.data;

                        this.doneLoading = true;
                        this.$store.commit('loading/setLoading', false);
                    });
            })

    },
    methods: {
        getAuctionStatus(startDate) {
            if(!this.$moment(startDate).isBefore(this.$moment())) {
                return this.$t(`dashboard.status_waiting`)
            }

            return this.$t(`dashboard.status_running`);
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            currentUser: 'auth/user'
        }),
        auctionFields() {
            return [
                {
                    key: 'title',
                    label: this.$t(`dashboard.label_auction`),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t(`dashboard.label_organizer`),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t(`dashboard.label_startdate`),
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: this.$t(`dashboard.label_enddate`),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t(`dashboard.label_status`)
                }
            ];
        },
        callForBidsFields() {
            return [
                {
                    key: 'title',
                    label: this.$t(`dashboard.label_callforbids`),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t(`dashboard.label_organizer`),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t(`dashboard.label_startdate`),
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: this.$t(`dashboard.label_enddate`),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t(`dashboard.label_status`)
                }
            ];
        },

    }
};
</script>

