<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #F8F8F8">
            <router-link :to="'/app/contracts'" href="#">{{ $t("contract.maintitle_view") }} </router-link>
            <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ contract.contract_title }}
        </div>
        <div class="card-body px-0" style="background-color: #F8F8F8">
            <b-tabs>
                <b-tab title="Vertragsstammdaten" active>
                    <b-card-group deck>
                        <b-card title="Basisdaten" class="mb-3 shadow">
                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertrags-ID:</label></b-col>
                                <b-col sm="9">{{contract.id}}</b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col sm="3"><label>Titel:</label></b-col>
                                <b-col sm="9">{{contract.contract_title}}</b-col>
                            </b-row>
<!--
                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertragsnummer:</label></b-col>
                                <b-col sm="9">{{contract.contract_number}}</b-col>
                            </b-row>
-->
                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertragspartner:</label></b-col>
                                <b-col sm="9">{{contract.contract_extern_partner}}</b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertragsbeginn:</label></b-col>
                                <b-col sm="9">{{contract.contract_startdate | moment('DD.MM.YYYY')}}</b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertragsende:</label></b-col>
                                <b-col sm="9">{{contract.contract_enddate | moment('DD.MM.YYYY')}}</b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col sm="3"><label>Kündigungsfrist:</label></b-col>
                                <b-col sm="9">{{contract.contract_period_of_notice}}</b-col>
                            </b-row>
                        </b-card>
                        <br />
                        <b-card title="Klassifizierung" class="mb-3 shadow">
                            <b-row>
                                <b-col sm="3"><label>Vertragsstatus:</label></b-col>
                                <b-col sm="9">{{contract.contract_state}}</b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3"><label>Vertragsanlass:</label></b-col>
                                <b-col sm="9">{{contract.contract_occasion}}</b-col>
                            </b-row>
<!--
                            <b-row>
                                <b-col sm="3"><label>Vertraulichkeit:</label></b-col>
                                <b-col sm="9">{{contract.contract_confidentiality}}</b-col>
                            </b-row>
-->
                            <b-row>
                                <b-col sm="3"><label>Vertragstyp:</label></b-col>
                                <b-col sm="9">{{contract.contract_type}}</b-col>
                            </b-row>
<!--
                            <b-row>
                                <b-col sm="3"><label>Strategische Bedeutung:</label></b-col>
                                <b-col sm="9">{{contract.contract_strategic_importance}}</b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3"><label>Risikostufe:</label></b-col>
                                <b-col sm="9">{{contract.contract_risk_level}}</b-col>
                            </b-row>
-->
                            <b-row>
                                <b-col sm="3"><label>Geschäftsbereich intern:</label></b-col>
                                <b-col sm="9">{{contract.contract_subject}}</b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3"><label>Vertragswert gesamte Laufzeit:</label></b-col>
                                <b-col sm="9">{{contract.contract_value}}</b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3"><label>Wertcluster:</label></b-col>
                                <b-col sm="9">{{contract.contract_value_cluster}}</b-col>
                            </b-row>
                        </b-card>
                    </b-card-group>
                    <br />
                    <b-card-group deck>
                        <b-card title="Fortführung und Beendigung" class="mb-3 shadow">
                            <b-row class="my-1">
                                <b-col sm="3"><label>Kündigungsfrist:</label></b-col>
                                <b-col sm="9">{{contract.contract_period_of_notice}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Spätester Kündigungstermin:</label></b-col>
                                <b-col sm="9">{{contract.contract_termination_date | moment('DD.MM.YYYY')}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Verlängerungsart:</label></b-col>
                                <b-col sm="9">{{contract.contract_extension_type}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Verlängerungsdauer:</label></b-col>
                                <b-col sm="9">{{contract.contract_extension_period}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Kündigungsform:</label></b-col>
                                <b-col sm="9">{{contract.contract_termination_form}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertrag gekündigt am...:</label></b-col>
                                <b-col sm="9">{{contract.contract_terminated_on | moment('DD.MM.YYYY')}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertrag gekündigt zum...:</label></b-col>
                                <b-col sm="9">{{contract.contract_terminated_at | moment('DD.MM.YYYY')}}</b-col>
                            </b-row>
                        </b-card>

                        <b-card title="Notizen" class="mb-3 shadow">
                            <b-row class="my-1">
                                <b-col sm="3"><label>Notizen zum Vertrag:</label></b-col>
                                <b-col sm="9">{{contract.contract_notes}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Schlüsselbegriffe:</label></b-col>
                                <b-col sm="9">{{contract.contract_keywords}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Physischer Ablageort:</label></b-col>
                                <b-col sm="9">{{contract.contract_physical_location}}</b-col>
                            </b-row>
                        </b-card>
                    </b-card-group>
                    <br />
                    <b-card-group deck>
                        <b-card title="Kontakte extern" class="mb-3 shadow">
                            <b-row class="my-1">
                                <b-col sm="3"><label>Partnerrolle extern:</label></b-col>
                                <b-col sm="9">
                                    <template v-if="contract.contract_extern_partner_role == 0">Kreditor</template>
                                    <template v-if="contract.contract_extern_partner_role == 1">Debitor</template>
                                    <template v-if="contract.contract_extern_partner_role == 2">Sonstige</template>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Vertragspartner:</label></b-col>
                                <b-col sm="9">{{contract.contract_extern_partner}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Verantwortlich extern:</label></b-col>
                                <b-col sm="9">{{contract.contract_extern_responsible}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Ansprechpartner extern:</label></b-col>
                                <b-col sm="9">{{contract.contract_extern_contact_person}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Adresse extern:</label></b-col>
                                <b-col sm="9">{{contract.contract_extern_adress}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>E-mail extern:</label></b-col>
                                <b-col sm="9">{{contract.contract_extern_email}}</b-col>
                            </b-row>
                        </b-card>

                        <b-card title="Kontakte intern" class="mb-3 shadow">
                            <b-row class="my-1">
                                <b-col sm="3"><label>Abteilung intern:</label></b-col>
                                <b-col sm="9">
                                    <template v-if="contract.contract_department == 0">Unternehmensleitung</template>
                                    <template v-if="contract.contract_department == 1">Verwaltung</template>
                                    <template v-if="contract.contract_department == 2">Marketing</template>
                                    <template v-if="contract.contract_department == 3">Vertrieb Handel</template>
                                    <template v-if="contract.contract_department == 4">Logistik</template>
                                    <template v-if="contract.contract_department == 5">Vertrieb Gastro</template>
                                    <template v-if="contract.contract_department == 6">IT</template>
                                    <template v-if="contract.contract_department == 7">QS und QM</template>
                                    <template v-if="contract.contract_department == 8">Produktion</template>
                                    <template v-if="contract.contract_department == 9">Abfüllung</template>
                                    <template v-if="contract.contract_department == 10">Betriebswerkstätten</template>
                                    <template v-if="contract.contract_department == 11">Technik Leitung</template>
                                    <template v-if="contract.contract_department == 12">Assistenz Technik Leitung</template>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Verantwortlich intern Management:</label></b-col>
                                <b-col sm="9">{{contract.contract_intern_management}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Verantwortlich intern:</label></b-col>
                                <b-col sm="9">{{contract.contract_intern_responsible}}</b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="3"><label>Ansprechpartner intern:</label></b-col>
                                <b-col sm="9">{{contract.contract_intern_contact_person}}</b-col>
                            </b-row>
                        </b-card>
                    </b-card-group>
                </b-tab>

                <b-tab title="Dokumente">
                    <b-card title="Hauptvertrag" class="mb-3 shadow">
                        <b-row class="my-1" v-if="contract.contract_filename">
                            <b-col sm="3"><label>Hinterlegter Hauptvertrag:</label></b-col>
                            <b-col sm="9">
                                {{contract.contract_filename}} 
                                <b-link class="m-1" href="#" @click="downloadContractFile()"><v-icon name="download"></v-icon></b-link>
                            </b-col>
                        </b-row>
                        <b-row class="my-1" v-if="!contract.contract_filename">
                            <b-col>Kein Hauptvertrag hinterlegt.</b-col>
                        </b-row>
                    </b-card>

                    <b-card title="Anlagen" class="mb-3 shadow">
                        <div class="table-scrollable">
                            <b-table striped hover :items="contract.appendices" :fields="appendixFields">
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" @click="downloadAppendix(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                </b-tab>

                <b-tab title="Wiedervorlagen">
                    <b-card title="Übersicht eingestellter Wiedervorlagen und Erinnerungen" class="mb-3 shadow">
                        <div class="table-scrollable">
                            <b-table striped hover :items="contract.reminders" :fields="reminderFields">
                            </b-table>
                        </div>
                    </b-card>
                </b-tab>
<!--
                <b-tab title="Referenzobjekte">
                    <b-card title="Übersicht zugewiesener Vergaben / Auktionen" class="mb-3 shadow">
                        // TODO Hier muss eine Tabelle erstellt werden, in der alle zugewiesenen Vergaben/Auktionen aufgelistet sind
                    </b-card>
                </b-tab>
-->
                <b-tab title="Benutzer">
                    <b-card title="Übersicht zugewiesener Benutzer" class="mb-3 shadow">
                        <div class="table-scrollable">
                            <b-table striped hover :items="contract.users" :fields="userfields">
                            </b-table>
                        </div>
                    </b-card>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {_} from 'vue-underscore';

import {mapGetters} from 'vuex';
import contractService from '../../../../services/ContractService';

export default {
    name: 'contract-view',
    data() {
        return {
            editMode: false,
            excelFile: null,
            doneLoading: false,
            reminderToDelete: {},
            appendixToDelete: {},
            contract: {
                contract_number: '',
                contract_title: '',
                appendices: [],
                contract_currency: 'Euro',
            },
            selectedUser: '',
            currentFile: {
                name: '',
            },
        }
    },
    created() {
        if(this.$route.params.id) {
            this.$store.commit('loading/setLoading', true);
            contractService.fetch(this.$route.params.id)
                .then(data => {
                    this.contract = data.data;
                    if (this.contract.contract_startdate) {this.contract.contract_startdate = this.$moment(this.contract.contract_startdate).toDate(); }
                    if (this.contract.contract_enddate) {this.contract.contract_enddate = this.$moment(this.contract.contract_enddate).toDate(); }
                    if (this.contract.contract_termination_date) {this.contract.contract_termination_date = this.$moment(this.contract.contract_termination_date).toDate(); }
                    if (this.contract.contract_earliest_termination_date) {this.contract.contract_earliest_termination_date = this.$moment(this.contract.contract_earliest_termination_date).toDate(); }
                    if (this.contract.contract_terminated_on) {this.contract.contract_terminated_on = this.$moment(this.contract.contract_terminated_on).toDate(); }
                    if (this.contract.contract_terminated_at) {this.contract.contract_terminated_at = this.$moment(this.contract.contract_terminated_at).toDate(); }
                    this.doneLoading = true;
                    this.$store.commit('loading/setLoading', false);
                });
        }
        else {
            this.doneLoading = true;
        }
        return;
    },
    methods: {
        downloadContractFile() {
            this.$store.commit('loading/setLoading', true);
            contractService.downloadContractFile(this.contract.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    const url = window.URL.createObjectURL(new Blob([response], {type: this.contract.contract_mimetype}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.contract.contract_filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadAppendix(item) {
            this.$store.commit('loading/setLoading', true);
            contractService.downloadAppendix(this.contract.id, item.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    const url = window.URL.createObjectURL(new Blob([response], {type: item.mimetype}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', item.appendix_filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
    },
    computed: {
        ...mapGetters({
            auctions: 'contracts/auctions',
            users: 'contracts/users',
            contracts: 'contracts/contracts',
            hasRole: 'auth/hasRole',
            appendixTypes: 'contracts/appendixTypes',
            contractConfidentialities: 'contracts/contractConfidentialities',
            contractExtensionTypes: 'contracts/contractExtensionTypes',
            contractOccasions: 'contracts/contractOccasions',
            contractRiskLevels: 'contracts/contractRiskLevels',
            contractStates: 'contracts/contractStates',
            contractStrategicImportances: 'contracts/contractStrategicImportances',
            contractSubjects: 'contracts/contractSubjects',
            contractTerminationForms: 'contracts/contractTerminationForms',
            contractTypes: 'contracts/contractTypes',
            contractValueClusters: 'contracts/contractValueClusters',
            resubmissionReasons: 'contracts/resubmissionReasons',
            resubmissionStates: 'contracts/resubmissionStates',
        }),
        userfields() {
            return [
                {   key: 'id',                  label: 'Id'             },
                {   key: 'name',                label: 'Name'           },
                {   key: 'company',             label: 'Firma'          },
                {   key: 'actions',             label: 'Aktionen'       }
            ];
        },
        appendixFields() {
            return [
                //{   key: 'contract_id',                  label: 'VertragsId'             },
                {   key: 'appendix_created_by.name',     label: 'Anlage erstellt durch'  },
                {   key: 'appendix_name',                label: 'Anlage Name'            },
                {   key: 'appendix_type.appendix_type',  label: 'Anlage Typ'             },
                {   key: 'appendix_startdate',           label: 'Anlage Beginn'          },
                {   key: 'appendix_enddate',             label: 'Anlage Ende'            },
                {   key: 'appendix_description',         label: 'Beschreibung'           },
                {   key: 'appendix_keywords',            label: 'Schlagworte'            },
                {   key: 'appendix_filename',            label: 'Dateibezeichnung'       },
                {   key: 'appendix_mimetype',            label: 'Dateityp'               },
                {   key: 'actions',                      label: 'Aktionen'               }
            ];
        },
        reminderFields() {
            return [
                {   key: 'contract_id',                     label: 'VertragsId'                 },
                {   key: 'reminder_created_by.name',        label: 'Erinnerung erstellt durch'  },
                {   key: 'reminder_date',                   label: 'Erinnerungsdatum'           },
                {   key: 'reminder_recipient_1_user.name',  label: 'Empfänger 1'                },
                {   key: 'reminder_recipient_2_user.name',  label: 'Empfänger 2'                },
                {   key: 'reminder_recipient_3_user.name',  label: 'Empfänger 3'                },
                {   key: 'resubmission_day_cycle',          label: 'Wiedervorlagezyklus'        },
                {   key: 'resubmission_state',              label: 'Wiedervorlagestatus'        },
                {   key: 'resubmission_reason',             label: 'Wiedervorlagegrund'         },
                {   key: 'resubmission_description',        label: 'Wiedervorlagebeschreibung'  },
                {   key: 'resubmission_alert',              label: 'Eskalation'                 },
                {   key: 'actions',                         label: 'Aktionen'                   }
            ];
        },
        fileTableFields() {
            return [
                {   key: 'name',                label: 'Bezeichnung'    },
                {   key: 'filename',            label: 'Dateiname'      },
                {   key: 'size',                label: 'Größe'          },
                {   key: 'mimetype',            label: 'Typ'            },
                {   key: 'actions',             label: 'Aktionen'       }
            ];
        },
    },
    watch: {
        auctionSearch: _.debounce(function(query) {
            this.searchAuction(query)
        } , 200)
    }
};
</script>
