<template>
    <div>
      <h4>Übersicht Einspareffekte</h4>
      <line-chart :chart-data="datacollection" :height="100"></line-chart>
    </div>
</template>

<script>
  
  import LineChart from './charts/LineChart.js'
  
  export default {
    components: {
      LineChart
    },
    data(){
      return {
        datacollection: null
      }
    },
    mounted () {
      this.fillData()
    },
    methods: {
  
      fillData ()
      {
        this.datacollection = {
          labels: [
            'Kohlensäure',
            'Stanzzuschnitte',
            'Dose 500ml',
            'Energie',
            'Braumalz',
            'Lebensmittelzusatzstoffe',
            'Invertzuckersirup',
            'Kronenkorken',
            'Flaschen',
            'Dose 5 Liter',
            'Etiketten',
            'Gläser',
            'Kartonage',
            'Saccharosesirup'
          ],
          datasets: [
            {
              label: 'Einsparsumme',
              backgroundColor: '#000120',
              data: [ 
              30000, 
              77509, 
              86345, 
              216551, 
              801000, 
              200640, 
              104500, 
              49141, 
              113145, 
              88547, 
              116960, 
              -4345, 
              0,
              0
              ],
            },
          ]
        }
      }
    }
  }
</script>