<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #F8F8F8">{{ $t("bidadmin.title") }}</div>
        <div class="card-body px-0" style="background-color: #F8F8F8">
            <b-card :title="$t(`bidadmin.card_bidadmin`) "class="mb-3 shadow">
                <template v-if="hasRole('Administrator') || hasRole('Berater') || hasRole('Erweiterter Einkäufer')">
                    <b-button v-b-toggle.help_bidadmin pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                    <b-sidebar right id="help_bidadmin" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                        <div class="px-3 py-2">
                            <b-list-group>
                                <b-list-group-item>{{ $t("help.bidadmin_1") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.bidadmin_2") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.bidadmin_3") }}</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-sidebar>
                    <br/>
                    <b-form-select v-model="selectedAuctionId" :placeholder="$t(`bidadmin.selectauction_placeholder`)" @change="auctionChanged($event)" :options="auctions" value-field="id" text-field="title" class="mb-3" />
                    <br />
                    <b-form-group horizontal :label="$t(`bidadmin.search_title`)" class="mb-0">
                    <b-input-group>
                        <b-form-input v-model="searchFilter" :placeholder="$t(`bidadmin.search_placeholder`)" />
                        <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t("bidadmin.search_reset") }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    </b-form-group>
                    <br />
                    <div class="table-scrollable">
                        <b-table ref="bidtable" striped hover :items="provideEntries" :fields="fields" :filter="searchFilter" :current-page="currentPage">
                            <template #cell(created_at)="data">
                                {{ data.item.created_at | moment("DD.MM.YYYY HH:mm")}}
                            </template>
                            <template #cell(bid)="data">
                                {{formatCurrencyFromInteger(data.item.bid)}}
                            </template>
                            <template #cell(actions)="data">
                                <b-link class="m-1" href="#" @click.prevent="editBid(data.item)" ><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                                <b-link class="m-1" href="#" @click.prevent="requestDelete(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                            </template>
                        </b-table>
                    </div>
                    <b-pagination :total-rows="totalEntries" v-model="currentPage" :per-page="20" align="center">
                    </b-pagination>
                </template>
            </b-card>
        </div>
        <b-modal ref="confirmationModal" hide-footer :title="$t(`bidadmin.delete_title`)">
            <div class="d-block text-center">
                <p >{{ $t("bidadmin.delete_message") }}</p>
            </div>
            <b-button class="mt-3" variant="outline-danger" block @click.prevent="deleteBid">{{ $t("bidadmin.delete_button") }}</b-button>
        </b-modal>
        <b-modal ref="correctionModal" hide-footer :title="$t(`bidadmin.correction_title`)">
            <div class="d-block text-center">
                <b-form-input v-model="currentItem.bid"/>
            </div>
            <b-button class="mt-3" variant="outline-danger" block @click.prevent="updateBid">{{ $t("bidadmin.correction_button") }}</b-button>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import callForBidsService from '../../../../services/CallForBidsService';
import numberHelper from '../../../../helpers/numberHelper';
import objectHelper from '../../../../helpers/objectHelper';

export default {
    name: 'bid-administration',
    data() {
        return {
            selectedAuctionId: null,
            selectedAuction: {},
            currentItem: {},
            auctionId: 0,
            totalEntries: 0,
            currentPage: 1,
            searchFilter: '',
        }
    },
    created() {
        this.listAuctions();
    },
    watch: {
    },
    methods: {
        ...mapActions({
            listAuctions: 'callForBids/fetchAuctions'
        }),
        provideEntries(ctx, cb) {
            if(!this.selectedAuctionId) {
                return [];
            }

            this.$store.commit('loading/setLoading', true);
            let offset = 0;

            if(ctx.currentPage > 1) {
                offset = (ctx.currentPage - 1) * 20;
            }

            return callForBidsService.fetchBidsForAuction(this.selectedAuctionId, offset)
                .then(response => {
                    this.totalEntries = response.total;
                    this.$store.commit('loading/setLoading', false);
                    return (response.bids);
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);
                    return [];
                })
        },
        auctionChanged(value) {
            this.selectedAuctionId = value;

            this.auctions.some(a => {
                if(a.id === value) {
                    this.selectedAuction = a;
                    return true;
                }
            })

            this.$refs.bidtable.refresh();
        },
        formatCurrencyFromInteger(value) {
            var precision = this.selectedAuction.precision;

            return numberHelper.toCurrency(value, precision);
        },
        editBid(item) {
            this.currentItem = objectHelper.copy(item);
            this.currentItem.bid = numberHelper.toCurrency(this.currentItem.bid, this.selectedAuction.precision);
            this.$refs.correctionModal.show();
        },
        requestDelete(item) {
            this.$refs.confirmationModal.show();
            this.currentItem = objectHelper.copy(item);
        },
        deleteBid() {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.delteBid(this.currentItem.id)
                .then(response => {
                    this.$refs.confirmationModal.hide();
                    this.currentItem = {};
                    this.$store.commit('loading/setLoading', false);
                    if(response.data.success) {
                        this.$refs.bidtable.refresh();
                        this.$notify({
                            group: 'global',
                            title: this.$t("bidadmin.notify_success_title"),
                            type: 'success',
                            text: this.$t("bidadmin.notify_success_message")
                        });
                    }else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("bidadmin.notify_error_title"),
                            type: 'error',
                            text: response.data.message
                        });
                    }
                })
        },
        updateBid() {
            this.$store.commit('loading/setLoading', true);
            callForBidsService.updateBid(this.currentItem.id, {bid: numberHelper.toCents(this.currentItem.bid, this.selectedAuction.precision)})
                .then(response => {
                    this.$refs.correctionModal.hide();
                    this.currentItem = {};
                    this.$store.commit('loading/setLoading', false);
                    if(response.data.success) {
                        this.$refs.bidtable.refresh();
                        this.$notify({
                            group: 'global',
                            title: this.$t("bidadmin.notify_correction_title"),
                            type: 'success',
                            text: this.$t("bidadmin.notify_correction_message")
                        });
                    }else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("bidadmin.notify_correction_error"),
                            type: 'error',
                            text: response.data.message
                        });
                    }
                })
        }
    },
    computed: {
        ...mapGetters({
            auctions: 'callForBids/auctions',
            hasRole: 'auth/hasRole'
        }),
        fields() {
            return [
                {
                    key: 'created_at',
                    label: this.$t(`bidadmin.created_at`),
                    sortable: true
                },
                {
                    key: 'user',
                    label: this.$t(`bidadmin.user`),
                    sortable: true
                },
                {
                    key: 'auctionItem',
                    label: this.$t(`bidadmin.position`),
                    sortable: true
                },
                {
                    key: 'bid',
                    label: this.$t(`bidadmin.bid`)
                },
                {
                    key: 'actions',
                    label: this.$t(`bidadmin.action`)
                },
            ];
        },
    }
}
</script>
