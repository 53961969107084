<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #F8F8F8"> 
            <router-link :to="'/app/projects'" href="#">{{ $t("project.maintitle_create") }} </router-link>
            <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ project.project_title }}
        </div>
        <div class="card-body px-0" style="background-color: #F8F8F8">
            <b-form @submit.prevent="onSubmit" v-show="doneLoading">
                <b-tabs>
                    <b-tab title="Projektstammdaten" active>
                        <b-card-group deck>
                            <b-card title="Grunddaten" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.project_number`)" label-for="project-number">
                                            <b-form-input id="project-number" type="text" v-model="project.project_number" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.project_title`)" label-for="project-title">
                                            <b-form-input id="project-title" type="text" v-model="project.project_title" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.responsible`)" label-for="project-responsible">
                                            <b-form-input id="project-responsible" type="text" v-model="project.responsible" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.team`)" label-for="project-team">
                                            <b-form-input id="project-team" type="text" v-model="project.team" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.year`)" label-for="project-year">
                                            <b-form-input id="project-year" type="text" v-model="project.year" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.period`)" label-for="project-period">
                                            <b-form-input id="project-period" type="text" v-model="project.period" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>

                            <b-card title="Projektsteuerung" class="mb-3 shadow">
                                <b-card-text>
                                    <b-form-group :label="$t(`project.projectphase`)" label-for="project-projectphase">
                                        <b-form-select v-model="project.projectphase" class="mb-3" style="background-color: #fdfdfd">
                                        <option value="0">{{ $t("project.projectphase_0") }}</option>
                                        <option value="1">{{ $t("project.projectphase_1") }}</option>
                                        <option value="2">{{ $t("project.projectphase_2") }}</option>
                                        <option value="3">{{ $t("project.projectphase_3") }}</option>
                                        <option value="4">{{ $t("project.projectphase_4") }}</option>
                                        </b-form-select>
                                    </b-form-group>

                                    <b-form-group :label="$t(`project.status_implementation`)" label-for="project-status-implementation">
                                        <b-form-select v-model="project.satus_implementation" class="mb-3" style="background-color: #fdfdfd">
                                        <option value="0">{{ $t("project.status_implementation_0") }}</option>
                                        <option value="1">{{ $t("project.status_implementation_1") }}</option>
                                        <option value="2">{{ $t("project.status_implementation_2") }}</option>
                                        <option value="3">{{ $t("project.status_implementation_3") }}</option>
                                        <option value="4">{{ $t("project.status_implementation_4") }}</option>
                                        </b-form-select>
                                    </b-form-group>

                                    <b-row><b-col>
                                        <b-form-checkbox v-model="project.archived"> Projekt archiviert</b-form-checkbox>
                                    </b-col></b-row>

                                    <br />
                                    
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.priority`)" label-for="project-priority">
                                            <b-form-input id="project-priority" type="text" v-model="project.priority" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.relevance`)" label-for="project-relevance">
                                            <b-form-input id="project-relevance" type="text" v-model="project.relevance" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
            
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.projectassignment`)" label-for="project-projectassignment">
                                            <b-form-input id="project-projectassignment" type="text" v-model="project.projectassignment" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
            
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.label`)" label-for="project-label">
                                            <b-form-tags id="project-label" type="text" placeholder="...Eingabe neuer Label mit Enter" tag-variant="secondary" v-model="project.label" style="background-color: #fdfdfd"></b-form-tags>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-form-group :label="$t(`project.reportingcircle`)" label-for="project-reportingcircle">
                                        <b-form-select v-model="project.reportingcircle" class="mb-3" style="background-color: #fdfdfd">
                                        <option value="0">{{ $t("project.reportingcircle_0") }}</option>
                                        <option value="1">{{ $t("project.reportingcircle_1") }}</option>
                                        <option value="2">{{ $t("project.reportingcircle_2") }}</option>
                                        <option value="3">{{ $t("project.reportingcircle_3") }}</option>
                                        <option value="4">{{ $t("project.reportingcircle_4") }}</option>
                                        </b-form-select>
                                    </b-form-group>

                                    <b-form-group :label="$t(`project.reportingcycle`)" label-for="project-reportingcycle">
                                        <b-form-select v-model="project.reportingcycle" class="mb-3" style="background-color: #fdfdfd">
                                        <option value="0">{{ $t("project.reportingcycle_0") }}</option>
                                        <option value="1">{{ $t("project.reportingcycle_1") }}</option>
                                        <option value="2">{{ $t("project.reportingcycle_2") }}</option>
                                        <option value="3">{{ $t("project.reportingcycle_3") }}</option>
                                        <option value="4">{{ $t("project.reportingcycle_4") }}</option>
                                        </b-form-select>
                                    </b-form-group>
                                </b-card-text>
                            </b-card>
                        </b-card-group>
                    </b-tab>

                    <b-tab title="Projektzuordnung">
                        <b-card-group deck>
                            <b-card title="Gesellschafts- und Objektzuordnung" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.company`)" label-for="project-company">
                                            <b-form-input id="project-company" type="text" v-model="project.company" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.location`)" label-for="project-location">
                                            <b-form-input id="project-location" type="text" v-model="project.location" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.businessunit`)" label-for="project-businessunit">
                                            <b-form-input id="project-businessunit" type="text" v-model="project.businessunit" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.department`)" label-for="project-department">
                                            <b-form-input id="project-department" type="text" v-model="project.department" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.account`)" label-for="project-account">
                                            <b-form-input id="project-account" type="text" v-model="project.account" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.account_number`)" label-for="project-account-number">
                                            <b-form-input id="project-account-number" type="text" v-model="project.account_number" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>
                            <b-card title="Kostengruppenhierarchie" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.category_level1`)" label-for="project-category-level1">
                                            <b-form-input id="project-category-level1" type="text" v-model="project.category_level1" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.category_level2`)" label-for="project-category-level2">
                                            <b-form-input id="project-category-level2" type="text" v-model="project.category_level2" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.category_level3`)" label-for="project-category-level3">
                                            <b-form-input id="project-category-level3" type="text" v-model="project.category_level3" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.category_level4`)" label-for="project-category-level4">
                                            <b-form-input id="project-category-level4" type="text" v-model="project.category_level4" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.commoditygroup`)" label-for="project-commoditygroup">
                                            <b-form-input id="project-commoditygroup" type="text" v-model="project.commoditygroup" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>
                        </b-card-group>
                    </b-tab>

                    <b-tab title="Konzepte">
                        <b-card-group deck>
                            <b-card title="Brainstorming und Konzepte" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.concept_ideas`)" label-for="project-concept-ideas">
                                            <b-form-textarea id="project-concept-ideas" placeholder="Brainstorming und erste Konzeptideen" v-model="project.concept_ideas" rows="4" style="background-color: #fdfdfd"></b-form-textarea>
                                        </b-form-group>
                                    </b-col></b-row>
            
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.concept_approaches`)" label-for="project-concept-approaches">
                                            <b-form-textarea id="project-concept-approaches" placeholder="Beschreibung der konkreteren Projektansätze zur Umsetzung" v-model="project.concept_approaches" rows="4" style="background-color: #fdfdfd"></b-form-textarea>
                                        </b-form-group>
                                    </b-col></b-row>
            
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.solution_description`)" label-for="project-solution-description">
                                            <b-form-textarea id="project-solution-description" placeholder="Auflistung der erfolgreichen Lösungsansätze" v-model="project.solution_description" rows="4" style="background-color: #fdfdfd"></b-form-textarea>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>

                            <b-card title="Kommentare und Notizen" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.notes`)" label-for="project-notes">
                                            <b-form-textarea id="project-notes" placeholder="allgemeine Notizen" v-model="project.notes" rows="5" style="background-color: #fdfdfd"></b-form-textarea>
                                        </b-form-group>
                                    </b-col></b-row>
            
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.comments`)" label-for="project-comments">
                                            <b-form-textarea id="project-comments" placeholder="Kommentare" v-model="project.comments" rows="5" style="background-color: #fdfdfd"></b-form-textarea>
                                        </b-form-group>
                                    </b-col></b-row>
            
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.tags`)" label-for="project-tags">
                                            <b-form-input id="project-tags" type="text" v-model="project.tags" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>

                            <b-card title="Problemstellungen" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.problems`)" label-for="project-problems">
                                            <b-form-textarea id="project-problems" placeholder="Problemstellungen" v-model="project.problems" rows="15" style="background-color: #fdfdfd"></b-form-textarea>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>
                        </b-card-group>
                    </b-tab>

                    <b-tab title="Kennzahlen">
                        <b-card-group deck>
                            <b-card title="Basisvolumen und Budgets" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.purchasing_volume`)" label-for="project-purchasing-volume">
                                            <b-form-input id="project-purchasing-volume" number v-model="project.purchasing_volume" :state="!$v.project.purchasing_volume.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.budget_planned`)" label-for="project-budget-planned">
                                            <b-form-input id="project-budget-planned" number v-model="project.budget_planned" :state="!$v.project.budget_planned.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.budget_revised`)" label-for="project-budget-revised">
                                            <b-form-input id="project-budget-revised" number v-model="project.budget_revised" :state="!$v.project.budget_revised.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.budget_actual`)" label-for="project-budget-actual">
                                            <b-form-input id="project-budget-actual" number v-model="project.budget_actual" :state="!$v.project.budget_actual.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>

                            <b-card title="Potenzialhöhe und Potenzialdauer" class="mb-3 shadow">
                                <b-card-text>
                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.potential_abs_onetime`)" label-for="project-potential-abs-onetime">
                                            <b-form-input id="project-potential-abs-onetime" number v-model="project.potential_abs_onetime" :state="!$v.project.potential_abs_onetime.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.potential_abs_recurring`)" label-for="project-potential-abs-recurring">
                                            <b-form-input id="project-potential-abs-recurring" number v-model="project.potential_abs_recurring" :state="!$v.project.potential_abs_recurring.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.potential_perc_onetime`)" label-for="project-potential-perc-onetime">
                                            <b-form-input id="project-potential-perc-onetime" number v-model="project.potential_perc_onetime" :state="!$v.project.potential_perc_onetime.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.potential_perc_recurring`)" label-for="project-potential-perc-recurring">
                                            <b-form-input id="project-potential-perc-recurring" number v-model="project.potential_perc_recurring" :state="!$v.project.potential_perc_recurring.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.potential_startdate`)" label-for="project-potential-startdate">
                                            <b-form-datepicker today-variant="info" v-model="project.potential_startdate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="project-potential-startdate" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>

                                    <b-row><b-col>
                                        <b-form-group :label="$t(`project.potential_enddate`)" label-for="project-potential-enddate">
                                            <b-form-datepicker today-variant="info" v-model="project.potential_enddate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="project-potential-enddate" :placeholder="$t(`common.nodateselected`)">
                                            </b-form-datepicker>
                                        </b-form-group>
                                    </b-col></b-row>
                                </b-card-text>
                            </b-card>

                            <b-card title="Kosten- und Liquiditätseffekte" class="mb-3 shadow">
                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.savings_abs_onetime`)" label-for="project-savings-abs-onetime">
                                        <b-form-input id="project-savings-abs-onetime" number v-model="project.savings_abs_onetime" :state="!$v.project.savings_abs_onetime.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.savings_abs_recurring`)" label-for="project-savings-abs-recurring">
                                        <b-form-input id="project-savings-abs-recurring" number v-model="project.savings_abs_recurring" :state="!$v.project.savings_abs_recurring.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.savings_perc_onetime`)" label-for="project-savings-perc-onetime">
                                        <b-form-input id="project-savings-perc-onetime" number v-model="project.savings_perc_onetime" :state="!$v.project.savings_perc_onetime.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.savings_perc_recurring`)" label-for="project-savings-perc-recurring">
                                        <b-form-input id="project-savings-perc-recurring" number v-model="project.savings_perc_recurring" :state="!$v.project.savings_perc_recurring.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.savings_startdate`)" label-for="project-savings-startdate">
                                        <b-form-datepicker today-variant="info" v-model="project.savings_startdate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="project-savings-startdate" :placeholder="$t(`common.nodateselected`)">
                                        </b-form-datepicker>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.savings_enddate`)" label-for="project-savings-enddate">
                                        <b-form-datepicker today-variant="info" v-model="project.savings_enddate" :locale="$i18n.locale" start-weekday="1" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="project-savings-enddate" :placeholder="$t(`common.nodateselected`)">
                                        </b-form-datepicker>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.liquidityeffect_abs_onetime`)" label-for="project-liquidityeffect-abs-onetime">
                                        <b-form-input id="project-liquidityeffect-abs-onetime" number v-model="project.liquidityeffect_abs_onetime" :state="!$v.project.liquidityeffect_abs_onetime.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>

                                <b-row><b-col>
                                    <b-form-group :label="$t(`project.liquidityeffect_abs_recurring`)" label-for="project-liquidityeffect-abs-recurring">
                                        <b-form-input id="project-liquidityeffect-abs-recurring" number v-model="project.liquidityeffect_abs_recurring" :state="!$v.project.liquidityeffect_abs_recurring.$error ? null : false" style="background-color: #fdfdfd"></b-form-input>
                                    </b-form-group>
                                </b-col></b-row>
                            </b-card>
                        </b-card-group>
                    </b-tab>
                </b-tabs>
                <b-row><b-col>
                    <b-button type="submit" variant="primary"><b-icon icon="save" />{{ $t("project.btn_save") }}</b-button>
                </b-col></b-row>
            </b-form>
            <br />
        </div>
    </div>
</template>
<script>
    import {_} from 'vue-underscore';
    import {mapGetters, mapActions} from 'vuex';
    import projectService from '../../../../services/ProjectService';
    import {helpers, required, numeric, decimal} from 'vuelidate/lib/validators';
    import { dateHelper } from '../../../mixins/dateHelper';
    import numberHelper from '../../../../helpers/numberHelper';

    export default {
        name: 'project-createedit',
        mixins: [dateHelper],
        data() {
            return {
                editMode: false,
                doneLoading: false,
                project: {
                name: '',
		        archived: false,
                },
            }
        },
        created() {
            if (this.$route.params.id) {
                this.$store.commit('loading/setLoading', true);
                projectService.fetch(this.$route.params.id)
                    .then(data => {
                        this.setProject(data.data);
                        this.editMode = true;
                        this.doneLoading = true;
                        this.$store.commit('loading/setLoading', false);
                    });
            } else {
                this.doneLoading = true;
            }
        },
        validations: {
            project: {
                project_title:                      { required },
                year:                               { numeric },
                purchasing_volume:                  { decimal },
                potential_abs_onetime:              { decimal },
                potential_abs_recurring:            { decimal },
                potential_perc_onetime:             { decimal },
                potential_perc_recurring:           { decimal },
                savings_abs_onetime:                { decimal },
                savings_abs_recurring:              { decimal },
                savings_perc_onetime:               { decimal },
                savings_perc_recurring:             { decimal },
                liquidityeffect_abs_onetime:        { decimal },
                liquidityeffect_abs_recurring:      { decimal },
                budget_planned:                     { decimal },
                budget_revised:                     { decimal },
                budget_actual:                      { decimal }
            }
        },
        methods: {
            ...mapActions({
                
            }),
            setProject(project) {
                this.project = project;
            },
            onSubmit() {
                this.$v.$touch();

                if(this.$v.$error) {
                    this.$notify({
                        group: 'global',
                        title: 'Eingabeprüfung',
                        type: 'error',
                        text: 'Eingabe unvollständig oder fehlerhaft'
                    });
                    return;
                }
                if(!this.editMode) {
                    this.create();
                }
                else {
                    this.update();
                }
            },
            create() {
                this.$store.commit('loading/setLoading', true);
                projectService.create(this.project)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);

                        if(response.data.id !== undefined) {
                            this.setProject(response.data);
                            this.editMode = true;

                            this.$notify({
                                group: 'global',
                                title: this.$t("project.notify_projectsuccess_title"),
                                type: 'success',
                                text: this.$t("project.notify_projectsuccess_message")
                            });

                        }else {
                            this.$notify({
                            group: 'global',
                            title: this.$t("project.notify_projectsuccess_error"),
                            type: 'error',
                            text: response.data.message
                        });
                        }
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);

                        this.$notify({
                            group: 'global',
                            title: this.$t("project.notify_projectsuccess_error"),
                            type: 'error',
                            text: error.message
                        });
                    });
            },
            update() {
                this.$store.commit('loading/setLoading', true);
                projectService.update(this.project.id, this.project)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);

                        if((response.success === undefined) && ((response.data.success === undefined) || (response.data.success === true))) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("project.notify_projectsave_title"),
                                type: 'success',
                                text: this.$t("project.notify_projectsave_message")
                            });

                        } else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("project.notify_projectsave_error"),
                                type: 'error',
                                text: (response.data === undefined) ? response.message : response.data.message,
                            });
                        }
                    })
                    .catch(error => {
                        this.$store.commit('loading/setLoading', false);

                        this.$notify({
                            group: 'global',
                            title: this.$t("project.notify_projectsave_error"),
                            type: 'error',
                            text: error.message
                        });
                    });
            },
            toCurrency(value) {
                return numberHelper.toCurrency(value, this.auction.precision);
            },
        },
        computed: {
            ...mapGetters({
            projects: 'projects/projects',
            }),
        },
    };
</script>
    
